import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Dropzone from 'react-dropzone';
import { updateVehicleAction, viewVehicleAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Loader from "react-loader-spinner";

const schema = yup.object().shape({
    make: yup.string().required("Make is required"),
    model: yup.string().required("Model is required"),
    registrationNumber: yup.string().required("Registration No. is required"),
    capacity: yup.number().required("Capacity is required"),
    numberPlate: yup.string().required("Number plate is required"),
});


function VehicleUpdate({ updateVehicleAction, viewVehicleAction }) {
    const history = useHistory();
    const [category, setVehicleData] = useState(null);
    const params = useParams();
    const id = params.id;
    const formik = useFormik({
        initialValues: {
            make: '',
            model: '',
            registrationNumber: '',
            capacity: '',
            numberPlate: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            values.id = id;
            updateVehicleAction(id, values, (err, response) => {
                if (!err) {
                    history.push('/vehicle');
                }
            })
        },
    });

    useEffect(() => {

        viewVehicleAction({ id: id }, (err, response) => {
            if (!err) {
                setVehicleData(response.data.data);
                formik.setValues({
                    make: response.data.data.make,
                    model: response.data.data.model,
                    registrationNumber: response.data.data.registrationNumber,
                    capacity: response.data.data.capacity,
                    numberPlate: response.data.data.numberPlate
                });
            }
        });
    }, [id]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {category ?
                    <>
                        <div className="header">
                            <h2>
                                Update Vehicle
                            </h2>
                        </div>
                        <div className="body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label>Make</label>
                                    <input
                                        className={`form-control ${formik.errors.make && formik.touched.make && "parsley-error"
                                            }`}
                                        value={formik.values.make}
                                        name="make"
                                        id="make"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.make && formik.touched.make &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.make}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Model</label>
                                    <input
                                        className={`form-control ${formik.errors.model && formik.touched.model && "parsley-error"
                                            }`}
                                        value={formik.values.model}
                                        name="model"
                                        id="model"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.model && formik.touched.model &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.model}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Registraton Number</label>
                                    <input
                                        className={`form-control ${formik.errors.registrationNumber && formik.touched.registrationNumber && "parsley-error"
                                            }`}
                                        value={formik.values.registrationNumber}
                                        name="registrationNumber"
                                        id="registrationNumber"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.registrationNumber && formik.touched.registrationNumber &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.registrationNumber}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Capacity</label>
                                    <input
                                        className={`form-control ${formik.errors.capacity && formik.touched.capacity && "parsley-error"
                                            }`}
                                        value={formik.values.capacity}
                                        name="capacity"
                                        id="capacity"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.capacity && formik.touched.capacity &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.capacity}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Number Plate</label>
                                    <input
                                        className={`form-control ${formik.errors.numberPlate && formik.touched.numberPlate && "parsley-error"
                                            }`}
                                        value={formik.values.numberPlate}
                                        name="numberPlate"
                                        id="numberPlate"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.numberPlate && formik.touched.numberPlate &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.numberPlate}
                                            </li>
                                        </ul>
                                    }
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                                </div>
                            </form>
                        </div>
                    </> :
                    <div className="text-center">
                        <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default connect(null, { updateVehicleAction, viewVehicleAction })(VehicleUpdate);
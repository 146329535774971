import React, { useState,useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createFaqAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

const schema = yup.object().shape({
    question: yup.string().required("Question is required"),
    answer: yup.string().required("Answer is required"),
});


function FaqCreate({ createFaqAction }) {
    const history = useHistory();
    const editorRef = useRef();
    
    const formik = useFormik({
        initialValues: {
            question: '',
            answer: '',
          
        },
        validationSchema: schema,
        onSubmit: values => {
            let faqValue={
                "question":values.question,
                "answer":values.answer
            }
            createFaqAction(faqValue, (err, response) => {
                if (!err) {
                    history.push('/faq');
                }
            })
        },
    });
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create FAQ 
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Question</label>
                            <input
                                className={`form-control ${formik.errors.question && formik.touched.question && "parsley-error"
                                    }`}
                                value={formik.values.question}
                                name="question"
                                id="question"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.question && formik.touched.question &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.question}
                                    </li>
                                </ul>
                            }
                        </div>
                       
                        <div className="form-group">
                            <label>Answer</label>
                            <input
                                className={`form-control ${formik.errors.answer && formik.touched.answer && "parsley-error"
                                    }`}
                                value={formik.values.answer}
                                name="answer"
                                id="answer"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.answer && formik.touched.answer &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.answer}
                                    </li>
                                </ul>
                            }
                        </div>
                      
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createFaqAction })(FaqCreate);
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Dropzone from 'react-dropzone';
import { updateWasteTypeCategoryAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Loader from "react-loader-spinner";

const schema = yup.object().shape({
    title: yup.string().required("Title is required")
});


function WasteTypeCategoryUpdate({ updateWasteTypeCategoryAction, wasteType }) {
    const history = useHistory();
    const params = useParams();
    const id = params.id;
    const formik = useFormik({
        initialValues: {
            title: wasteType.title
        },
        validationSchema: schema,
        onSubmit: values => {
            updateWasteTypeCategoryAction(id, values, (err, response) => {
                if (!err) {
                    history.push(`/waste-type-category`);
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                {wasteType ?
                    <>
                        <div className="header">
                            <h2>
                                Update Waste Type
                            </h2>
                        </div>
                        <div className="body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        className={`form-control ${formik.errors.title && formik.touched.title && "parsley-error"
                                            }`}
                                        value={formik.values.title}
                                        name="title"
                                        id="title"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.title && formik.touched.title &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.title}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                                </div>
                            </form>
                        </div>
                    </> :
                    <div className="text-center">
                        <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default connect(null, { updateWasteTypeCategoryAction })(WasteTypeCategoryUpdate);

import React from "react";
import PageHeader from "../../components/PageHeader";
import CouponUpdateComponent from "../../components/Coupon/CouponUpdate";

class CouponUpdate extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div
                style={{ flex: 1 }}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="Coupon Update"
                            Breadcrumb={[
                                { name: "Coupons", navigate: "/coupons" },
                                { name: "Coupon Update", navigate: "" },
                            ]}
                        />
                        <div className="row clearfix">
                            <CouponUpdateComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CouponUpdate;

import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { updateCmsAction, viewCmsAction } from '../../actions';
import { connect } from 'react-redux';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from 'react-router';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import Select from 'react-select';
import * as _ from 'underscore';

const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string(),
    seoTitle: yup.string(),
    seoDescription: yup.string(),
    keywords: yup.string(),
    belongsToWhereWeOperate: yup.boolean().default(false),
    category: yup.number().default(0).optional()
});


function CmsUpdate({ updateCmsAction, viewCmsAction }) {
    const history = useHistory();
    const editorRef = useRef();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [saveHtml, setSaveHtml] = useState();
    const [savedData, setSavedData] = useState();
    const params = useParams();
    const id = params.id;
    const [categories] = useState([
        {
            value: 0,
            label: 'London Boroughs'
        },
        {
            value: 1,
            label: 'All Parts of London'
        }   
    ])
    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            seoTitle: '',
            seoDescription: '',
            keywords: '',
            belongsToWhereWeOperate: false,
            category: 0
        },
        validationSchema: schema,
        onSubmit: values => {
            let cmsValue = {
                "title": values.title,
                "description": saveHtml,
                "seoTitle": values.seoTitle,
                "seoDescription": values.seoDescription,
                "keywords": values.keywords,
                 belongsToWhereWeOperate: values.belongsToWhereWeOperate,
                 category: values.category
            }
            updateCmsAction(id, cmsValue, (err, response) => {
                if (!err) {
                    history.push('/cms-pages');
                }
            })
        },
    });
    useEffect(() => {

        viewCmsAction({ id: id }, (err, response) => {
            if (!err) {
                setSavedData(response.data.data);
                formik.setValues({
                    title: response.data.data.title,
                    description: response.data.data.description,
                    seoTitle: response.data.data.seoTitle,
                    seoDescription: response.data.data.seoDescription,
                    keywords: response.data.data.keywords,
                    belongsToWhereWeOperate: response.data.data.belongsToWhereWeOperate,
                    category: response.data.data.category
                });
                console.log(" **** response.data.data.category **** ", response.data.data.category);
                const html = response.data.data.description;
                setSaveHtml(html);
                if (html) {
                    const contentBlock = htmlToDraft(html);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap)
                        setEditorState(EditorState.createWithContent(contentState))
                    } else {
                        setEditorState(EditorState.createEmpty())
                    }
                } else {
                    setEditorState(EditorState.createEmpty());
                }

            }
        });
    }, [id]);
    return (
        <div className="col-lg-12">
            <div className="card">
                {savedData ?
                    <>
                        <div className="header">
                            <h2>
                                Update CMS Pages
                            </h2>
                        </div>
                        <div className="body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        className={`form-control ${formik.errors.title && formik.touched.title && "parsley-error"
                                            }`}
                                        value={formik.values.title}
                                        name="title"
                                        id="title"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.title && formik.touched.title &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.title}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className='form-group'>
                                    <Editor
                                        defaultEditorState={editorState}
                                        editorState={editorState}
                                        onEd
                                        onContentStateChange={(contentState) => {
                                            const html = draftToHtml(contentState)
                                            setSaveHtml(html);
                                            formik.setFieldValue('description', html);
                                        }}
                                        onEditorStateChange={setEditorState}
                                        wrapperClassName="wrapper-class"
                                        name="description"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                    />
                                    {
                                        formik.errors.description && (formik.touched.description) &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.description}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Seo Title</label>
                                    <input
                                        className={`form-control ${formik.errors.seoTitle && formik.touched.seoTitle && "parsley-error"
                                            }`}
                                        value={formik.values.seoTitle}
                                        name="seoTitle"
                                        id="seoTitle"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.seoTitle && formik.touched.seoTitle &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.seoTitle}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Seo Description</label>
                                    <input
                                        className={`form-control ${formik.errors.seoDescription && formik.touched.seoDescription && "parsley-error"
                                            }`}
                                        value={formik.values.seoDescription}
                                        name="seoDescription"
                                        id="seoDescription"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.seoDescription && formik.touched.seoDescription &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.seoDescription}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Keywords</label>
                                    <input
                                        className={`form-control ${formik.errors.keywords && formik.touched.keywords && "parsley-error"
                                            }`}
                                        value={formik.values.keywords}
                                        name="keywords"
                                        id="keywords"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.keywords && formik.touched.keywords &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.keywords}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className={`form-check-input ${formik.errors.belongsToWhereWeOperate && formik.touched.belongsToWhereWeOperate && "parsley-error"
                                            }`} type="checkbox"
                                            name="belongsToWhereWeOperate"
                                            checked={formik.values.belongsToWhereWeOperate}
                                            onChange={formik.handleChange} id="belongsToWhereWeOperate" />
                                        <label className="form-check-label" htmlFor="belongsToWhereWeOperate">
                                            Display Under Where we operate?
                                        </label>
                                    </div>
                                </div>
                                {formik.values.belongsToWhereWeOperate && <div className="form-group">
                                        <label>Category</label>
                                        <Select
                                            value={_.find(categories, (item) => item.value === formik.values.category)}
                                            onChange={(value) => {
                                                formik.setFieldValue('category', value.value);
                                            }}
                                            name="category"
                                            options={categories}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                        {
                                            formik.errors.category && formik.touched.category &&
                                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                <li className="parsley-required">
                                                    {formik.errors.category}
                                                </li>
                                            </ul>
                                        }
                                    </div>}
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                                </div>
                            </form>
                        </div>
                    </> :
                    <div className="text-center">
                        <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        />
                    </div>
                }  
            </div >
        </div >
    )
}

export default connect(null, { updateCmsAction, viewCmsAction })(CmsUpdate);
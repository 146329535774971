import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { viewInvoiceAction, updateInvoiceAction, downloadInvoiceAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Accordion, useAccordionToggle } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { BASE_URL } from '../../config/constants';

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a
            style={{ color: "#007bff", cursor: "pointer" }}
            onClick={decoratedOnClick}
        >
            {children}
        </a>
    );
}

function InvoiceView({ invoice, updateInvoiceAction, downloadInvoiceAction }) {

    const params = useParams();
    const id = params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(reactLocalStorage.getObject('user', null));

    const updateInvoice = (status) => {
        setLoading(true);
        updateInvoiceAction(id, { id: id, status }, (err, response) => {
            if (!err) {
                setLoading(false);
                history.push('/invoices')
            }
        });
    }

    const dowloadInvoice = (id) => {
        setLoading(true);
        downloadInvoiceAction(id, { id: id }, (err, response) => {
            if (!err) {
                setLoading(false);
            }
        });
    }



    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    invoice ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    Invoice ID :{invoice.invoiceID}
                                </h2>
                                <div>
                                    {((invoice.status === 'AUTHORISED') && (user.role === 'SuperAdmin')) && <button onClick={() => { updateInvoice('HOLD') }} className="btn btn-outline-secondary mr-2" >{loading ? <i className='fa fa-spinner'></i> : `Mark Hold`}</button>}
                                    {((invoice.status === 'HOLD') && (user.role === 'SuperAdmin')) && <button onClick={() => { updateInvoice('AUTHORISED') }} className="btn btn-outline-secondary mr-2" >{loading ? <i className='fa fa-spinner'></i> : `Unhold`}</button>}
                                    {((invoice.type === 'ACCPAY') && (user.role === 'SuperAdmin')) && <a download href={`${BASE_URL}/admin/invoice/download?id=${invoice.bookingId}&token=${localStorage.getItem('access_token')}`} className="btn btn-outline-secondary" >{loading ? <i className='fa fa-spinner'></i> : `Download`}</a>}
                                </div>
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>First Name:</th>
                                            <td>{invoice.userId.firstName}</td>

                                        </tr>
                                        <tr>
                                            <th>Last Name:</th>
                                            <td>{invoice.userId.lastName}</td>
                                        </tr>
                                        <tr>
                                            <th>Status:</th>
                                            <td>{invoice.status}</td>
                                        </tr>
                                        <tr>
                                            <th>Amount:</th>
                                            <td>{'£' + invoice.total}</td>
                                        </tr>
                                        <tr>
                                            <th>Tax:</th>
                                            <td>{'£' + invoice.totalTax}</td>
                                        </tr>
                                        <tr>
                                            <th>Invoice Number:</th>
                                            <td>{invoice.invoiceNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Type:</th>
                                            <td>{invoice.type}</td>
                                        </tr>
                                        <tr>
                                            <th>Items:</th>
                                            <td>
                                                <Accordion>
                                                    <CustomToggle eventKey="0">Click Here</CustomToggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <div style={{ marginTop: "10px" }}>
                                                            {
                                                                invoice.lineItems.map(item => {
                                                                    return <div>
                                                                        <p className="mb-1"><b>Item: </b>{item.description}</p>
                                                                        <p className="mb-1"><b>Price: </b>{item.unitAmount}</p>
                                                                        <p className="mb-1"><b>Quantity: </b>{item.quantity}</p>
                                                                        <hr></hr>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Accordion>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(invoice.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </> :
                        <div className="text-center mt-2">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>
                }

            </div>
        </div>
    )
}

export default connect(null, { viewInvoiceAction, updateInvoiceAction, downloadInvoiceAction })(InvoiceView)
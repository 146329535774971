
import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import SubCategoryItemUpdateComponent from "../../components/SubCategoryItem/SubCategoryItemUpdate";
import Loader from "react-loader-spinner";
import { viewSubCategoryItemAction } from "../../actions";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

function SubCategoryItemUpdate({ viewSubCategoryItemAction }) {

    const params = useParams();
    const id = params.id;
    const [categoryItem, setCategoryItemData] = useState(null);
   
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewSubCategoryItemAction({ id: id }, (err, response) => {
                if (!err) {
                    setCategoryItemData(response.data.data);
                }
            });
        }
    }, [id]);
    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {categoryItem && <PageHeader
                        HeaderText="Sub Category Item Update"
                        Breadcrumb={[
                            { name: "Categories", navigate: "/categories" },
                            { name: categoryItem.categoryId.title, navigate: `/categories/view/${categoryItem.categoryId._id}` },
                            { name: 'Category Items', navigate: `/sub-category-item/${categoryItem.categoryId._id}/${categoryItem.categoryItemId._id}` },
                            { name: categoryItem.title, navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix justify-content-center">
                        {categoryItem ? <SubCategoryItemUpdateComponent categoryItem={categoryItem} />
                            : <div className="text-center" style={{marginTop: "50px"}}>
                                <Loader
                                    type="Bars"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    timeout={3000} //3 secs
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewSubCategoryItemAction })(SubCategoryItemUpdate)

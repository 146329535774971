import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { viewUserAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import constants from '../../config/constants';

function AppUserView({ viewUserAction }) {
    const [userData, setUserData] = useState(null);
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        viewUserAction({ id: id }, (err, response) => {
            if (!err) {
                setUserData(response.data.data);
            }
        });
    }, [id, viewUserAction]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    userData ?
                        <>
                            <div className="header">
                                <h2>
                                    {userData.firstName + ' ' + userData.lastName}
                                </h2>
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{userData.email}</td>

                                        </tr>
                                        <tr>
                                            <th>First Name:</th>
                                            <td>{userData.firstName}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Name:</th>
                                            <td>{userData.lastName}</td>
                                        </tr>
                                        <tr>
                                            <th>Role:</th>
                                            <td><span className="badge badge-primary">{userData.role}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Status:</th>
                                            <td>{(userData.status === 0) ? <span className="badge badge-danger">Inactive</span> : <span className="badge badge-success">Active</span>}</td>
                                        </tr>
                                        {userData.role === constants.ROLE.USER && <>
                                            <tr>
                                                <th>Is Business ? :</th>
                                                <td>{userData.isBusiness ? "Yes" : "No"}</td>
                                            </tr>
                                            {
                                                userData.isBusiness && <>
                                                    <tr>
                                                        <th>Company Name:</th>
                                                        <td>{userData.businessName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Registration Number:</th>
                                                        <td>{userData.companyRegistrationNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Address:</th>
                                                        <td>{userData.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Town:</th>
                                                        <td>{userData.town}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Postal Code:</th>
                                                        <td>{userData.postalCode}</td>
                                                    </tr>
                                                </>
                                            }
                                        </>}
                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(userData.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </> :
                        <div className="text-center">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>

                }

            </div>
        </div>
    )
}

export default connect(null, { viewUserAction })(AppUserView)
import React, { useState } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { listPayoutAction } from '../../actions';
import moment from 'moment-timezone';
import { useHistory } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';

function PayoutList({ listPayoutAction }) {
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [total, setTotal] = useState(0);
    const [tableState, setTableState] = useState(null);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(20);
    const history = useHistory();

    const loadData = (data) => {
        setLoading(true);
        listPayoutAction(data, (err, response) => {
            if (!err) {
                setProperties(response.data.data);
                setTotal(response.data.total);
            }
            setLoading(false);
        });
    }

    const columns = [
        {
            dataField: 'userFirstName',
            text: 'First Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.userId ? row.userId.firstName : '';
            }
        },
        {
            dataField: 'userLastName',
            text: 'Last Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.userId ? row.userId.lastName : '';
            }
        },
        {
            dataField: 'userEmail',
            text: 'Email',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.userId ? row.userId.email : '';
            }
        },
        {
            dataField: 'bookingId.bookingToken',
            text: 'Booking',
            formatter: (cell, row) => {
                return row.bookingId ? row.bookingId.bookingToken : '';
            }
        },
        {
            dataField: 'total',
            text: 'Amount',
            formatter: (cell, row) => {
                return '£' + row.amount;
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            formatter: (cell, row) => {
                return moment(row.createdAt).format('YYYY-MM-DD hh:mm a')
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => {
                return <>
                    <button onClick={() => { history.push(`/payout/view/${row._id}`) }} className="btn btn-outline-secondary mr-1"><i className="icon-eye"></i></button>
                </>
            },
        },
    ];

    const onTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        let data = { ...newState };
        data.perPage = newState.sizePerPage;
        delete data.data;
        setTableState(data);
        loadData(data);
    }

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="body table-responsive">
                    <BootstrapTable bootstrap4={true}
                        keyField='_id'
                        loading={loading}
                        data={properties}
                        columns={columns}
                        headerWrapperClasses="thead-light"
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize: total,
                            withFirstAndLast: true,
                            alwaysShowAllBtns: true
                        })}
                        filter={filterFactory()}
                        filterPosition="top"
                        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
                        overlay={overlayFactory()}
                        onTableChange={onTableChange}
                        remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: false
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default connect(null, {
    listPayoutAction
})(PayoutList)
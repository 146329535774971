import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { viewCouponAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function CouponView({ viewCouponAction }) {
    const [couponData, setCouponData] = useState(null);
    const history = useHistory();
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        viewCouponAction({ id: id }, (err, response) => {
            if (!err) {
                setCouponData(response.data.data);
            }
        });
    }, [id, viewCouponAction]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    couponData ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {couponData.title}
                                </h2>
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Title:</th>
                                            <td>{couponData.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Description:</th>
                                            <td>{couponData.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Type:</th>
                                            <td>{couponData.type === 0 ? "Flat": "Percentage"}</td>
                                        </tr>
                                        <tr>
                                            <th>Flat Amount:</th>
                                            <td>{couponData.flatAmount}</td>
                                        </tr>
                                        <tr>
                                            <th>Percentage:</th>
                                            <td>{couponData.percentage}</td>
                                        </tr>
                                        <tr>
                                            <th>Maximum Discount:</th>
                                            <td>{couponData.maximumDiscount}</td>
                                        </tr>
                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(couponData.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </> :
                        <div className="text-center">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>

                }

            </div>
        </div>
    )
}

export default connect(null, { viewCouponAction })(CouponView)
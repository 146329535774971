import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { listBookingAction, deleteBookingAction } from '../../actions';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { reactLocalStorage } from "reactjs-localstorage";
import { useFormik } from 'formik';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';

function BookingList({ listBookingAction, deleteBookingAction }) {
    const user = reactLocalStorage.getObject('user', null);
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [total, setTotal] = useState(0);
    const [tableState, setTableState] = useState(null);
    const [page, setPage] = useState(1);
    const [customFilters, setCustomFilters] = useState({})
    const [sizePerPage, setSizePerPage] = useState(20);
    const history = useHistory();

    const loadData = (data) => {
        setLoading(true);
        listBookingAction(data, (err, response) => {
            if (!err) {
                setProperties(response.data.data);
                setTotal(response.data.total);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            loadData(tableState);
        }, 30000);

        return () => clearInterval(interval);
    }, [tableState]);


    const formik = useFormik({
        initialValues: {
            fromDate: '',
            toDate: '',
            introducerCode: '',
            completedAt: ''
        },
        onSubmit: (values) => {
            setPage(1);
            let data = { ...tableState, page: 1 };
            data.perPage = sizePerPage;
            setCustomFilters(values);
            data.customFilters = customFilters;
            data.filters = { ...data.filters, ...values }
            setTableState(data);
            loadData(data);
        },
    });

    const confirmDelete = (ids) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);
                        deleteBookingAction({ ids: ids }, (err, response) => {
                            if (!err) {
                                loadData(tableState);
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };





    const columns = [
        {
            dataField: 'bookingToken',
            text: 'Booking Reference',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.status === 0 ? '' : row.bookingToken;
            }
        },
        {
            dataField: 'userFirstName',
            text: 'Customer First Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.userId ? row.userId.firstName : '';
            }
        },
        {
            dataField: 'userLastName',
            text: 'Customer Last Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.userId ? row.userId.lastName : '';
            }
        },
        {
            dataField: 'collectorFirstName',
            text: 'Collector First Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.collectorId ? row.collectorId.firstName : '';
            }
        },
        {
            dataField: 'collectorLastName',
            text: 'Collector Last Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                return row.collectorId ? row.collectorId.lastName : '';
            }
        },
        {
            dataField: 'totalPrice',
            text: 'Total Price',
            filter: textFilter(),
            formatter: (cell, row) => {
                return '£' + row.total;
            }
        },
        {
            dataField: 'vat',
            text: 'Tax',
            formatter: (cell, row) => {
                return '£' + row.vat;
            }
        },
        {
            dataField: 'address',
            text: 'Address'
        },
        {
            dataField: 'introducerCode',
            text: 'Introducer Code',
            formatter: (cell, row) => {
                return row.introducerId ? row.introducerId.introducerCode : 'NA';
            }
        },
        {
            dataField: 'bookingStatus',
            text: 'Status',
            formatter: (cell, row) => {
                if ((row.status === 0) && (row.paymentStatus === 0)) {
                    return <span className="badge badge-info">Abandoned</span>
                } else if ((row.paymentStatus === 2) && (row.bookingStatus === 1)) {
                    return <span className="badge badge-info">Awaiting Payment</span>
                } else if (row.bookingStatus === 1) {
                    return <span className="badge badge-success">Placed</span>
                } else if (row.bookingStatus === 3) {
                    return <span className="badge badge-success">Completed</span>
                } else if (row.bookingStatus === 4) {
                    return <span className="badge badge-success">Claimed</span>
                } else if (row.bookingStatus === 5) {
                    return <span className="badge badge-danger">Photos Uploaded</span>
                } else if (row.bookingStatus === 6) {
                    return <span className="badge badge-success">Customer Signed</span>
                } else if (row.bookingStatus === 7) {
                    return <span className="badge badge-success">Collector Signed</span>
                } else if (row.bookingStatus === 8) {
                    return <span className="badge badge-success">Final Photos Uploaded</span>
                } else if (row.bookingStatus === 9) {
                    return <span className="badge badge-success">Tipping Location Selected</span>
                } else if (row.bookingStatus === 10) {
                    return <span className="badge badge-success">Completed</span>
                } else if (row.bookingStatus === 11) {
                    return <span className="badge badge-success">Cancelled</span>
                } else if (row.bookingStatus === 12) {
                    return <span className="badge badge-success">Assigned</span>
                }
            },
            filter: selectFilter({
                options: {
                    1: "Placed",
                    12: "Assigned",
                    2: "In Progress",
                    3: "Completed",
                    4: "Claimed",
                    11: "Cancelled"
                }
            })
        },
        {
            dataField: 'datetime',
            text: 'Collection Date',
            sort: true,
            formatter: (cell, row) => {
                return row.datetime ? moment(row.datetime).format('YYYY-MM-DD') + " (" + row.time + ")" : 'NA';
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            formatter: (cell, row) => {
                return moment(row.createdAt).format('YYYY-MM-DD hh:mm a')
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => {
                return <>
                    <button onClick={() => { history.push(`/booking/view/${row._id}`) }} className="btn btn-outline-secondary mr-1"><i className="icon-eye"></i></button>
                    <button onClick={() => { confirmDelete([row._id]) }} className="btn btn-outline-secondary mr-1"><i className="icon-trash"></i></button>
                </>
            },
        },
    ];

    const onTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        let data = { ...newState };
        data.perPage = newState.sizePerPage;
        delete data.data;
        data.customFilters = customFilters;
        data.filters = { ...data.filters, ...customFilters };
        setTableState(data);
        loadData(data);
    }

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="row align-items-end">
                    <div className="col-md-8">
                        <div className="booking-filters px-4">
                            <form className="row align-items-end" onSubmit={formik.handleSubmit}>
                                <div className="col-md-3">
                                    <div className="form-group mb-2 mb-md-0 mt-2">
                                        <label className="control-label">From Date</label>
                                        <Datetime input={true} timeFormat={false} onChange={(value) => {
                                            if (value && value.toISOString) {
                                                formik.setFieldValue('fromDate', value.toISOString());
                                            } else {
                                                formik.setFieldValue('fromDate', '');
                                            }
                                        }} />

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-2 mb-md-0 mt-2">
                                        <label className="control-label">To Date</label>
                                        <Datetime input={true} timeFormat={false} onChange={(value) => {
                                            if (value && value.toISOString) {
                                                formik.setFieldValue('toDate', value.toISOString());
                                            } else {
                                                formik.setFieldValue('fromDate', '');
                                            }
                                        }} />
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <div className="form-group mb-2 mb-md-0 mt-2">
                                        <label className="control-label">Date Of Collection</label>
                                        <Datetime input={true} timeFormat={false} onChange={(value) => {
                                             if(value && value.toISOString){
                                                formik.setFieldValue('completedAt', value.toISOString());
                                            } else {
                                                formik.setFieldValue('completedAt', '');
                                            }
                                        }} />
                                    </div>
                                </div> */}
                                <div className="col-md-3">
                                    <div className="form-group  mb-2 mb-md-0 mt-2">
                                        <label className="control-label">Introducer Code</label>
                                        <input
                                            className={`form-control ${formik.errors.introducerCode && formik.touched.introducerCode && "parsley-error"
                                                }`}
                                            value={formik.values.introducerCode}
                                            name="introducerCode"
                                            id="introducerCode"
                                            type="text"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button type="submit" onClick={formik.handleSubmit} className="btn btn-primary">Filter</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4 text-right">
                        {(user.role === 'SuperAdmin') && <div className="header text-right pb-0">
                            <Link to="/booking/create" className="btn btn-primary btn-sm">Create</Link>
                        </div>}
                    </div>
                </div>


                <div className="body table-responsive TableHeight">
                    <BootstrapTable bootstrap4={true}
                        keyField='_id'
                        loading={loading}
                        data={properties}
                        columns={columns}
                        headerWrapperClasses="thead-light"
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize: total,
                            withFirstAndLast: true,
                            alwaysShowAllBtns: true
                        })}
                        filter={filterFactory()}
                        filterPosition="top"
                        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
                        overlay={overlayFactory()}
                        onTableChange={onTableChange}
                        remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: false
                        }}
                    />
                </div>
            </div>
        </div>
    );
}


export default connect(null, {
    listBookingAction, deleteBookingAction
})(BookingList)
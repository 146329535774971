import React from "react";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { Tabs, Tab } from "react-bootstrap";
import ProfileV1Setting from "../../components/Pages/ProfileV1Setting";

class ProfileV1Page extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div
				style={{ flex: 1 }}
				onClick={() => {
					document.body.classList.remove("offcanvas-active");
				}}
			>
				<div>
					<div className="container-fluid">
						<PageHeader
							HeaderText="User Profile v1"
							Breadcrumb={[
								{ name: "Dashboard", navigate: "/dashboard" },
								{ name: "Profile", navigate: "" },
							]}
						/>
						<div className="row clearfix">
							<div className="col-lg-12">
								<div className="card">
									<div className="body">
										<Tabs
											defaultActiveKey="settings"
											id="uncontrolled-tab-example"
										>
											<Tab eventKey="settings" title="settings">
												<ProfileV1Setting />
											</Tab>
										</Tabs>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ ioTReducer }) => ({
	isSecuritySystem: ioTReducer.isSecuritySystem,
});

export default connect(mapStateToProps, {})(ProfileV1Page);

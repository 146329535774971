import React, { useState } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { listNewsCategoryAction, deleteNewsCategoryAction } from '../../actions';
import moment from 'moment-timezone';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function NewsCategoryList({ listNewsCategoryAction, deleteNewsCategoryAction }) {
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [tableState, setTableState] = useState(null);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(20);
    const history = useHistory();

    const loadData = (data) => {
        setLoading(true);
        listNewsCategoryAction(data, (err, response) => {
            if (!err) {
                setProperties(response.data.data);
            }
            setLoading(false);
        });
    }

    const confirmDelete = (ids) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);
                        deleteNewsCategoryAction({ ids: ids }, (err, response) => {
                            if (!err) {
                                loadData(tableState);
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };


    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            filter: textFilter()
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            formatter: (cell, row) => {
                return moment(row.createdAt).format('YYYY-MM-DD hh:mm a')
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => {
                return <>
                    <button onClick={() => { history.push(`/news-categories/view/${row._id}`) }} className="btn btn-outline-secondary mr-1"><i className="icon-eye"></i></button>
                    <button onClick={() => { history.push(`/news-categories/update/${row._id}`) }} className="btn btn-outline-secondary mr-1"><i className="icon-pencil"></i></button>
                    <button onClick={() => { confirmDelete([row._id]) }} className="btn btn-outline-secondary mr-1"><i className="icon-trash"></i></button>
                </>
            },
        },
    ];

    const onTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        let data = { ...newState };
        data.perPage = newState.sizePerPage;
        delete data.data;
        setTableState(data);
        loadData(data);
    }

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header text-right pb-0">
                    <Link to="/news-categories/create" className="btn btn-primary btn-sm">Create</Link>
                </div>
                <div className="body table-responsive">
                    <BootstrapTable bootstrap4={true}
                        keyField='_id'
                        loading={loading}
                        data={properties}
                        columns={columns}
                        headerWrapperClasses="thead-light"
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            withFirstAndLast: true,
                            alwaysShowAllBtns: true
                        })}
                        filter={filterFactory()}
                        filterPosition="top"
                        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
                        overlay={overlayFactory()}
                        onTableChange={onTableChange}
                        remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: false
                        }}
                    />
                </div>
            </div>
        </div>
    );
}


export default connect(null, {
    listNewsCategoryAction, deleteNewsCategoryAction
})(NewsCategoryList)
import React from "react";
import PageHeader from "../../components/PageHeader";
import WasteTypeCreateComponent from "../../components/WasteType/WasteTypeCreate";

function WasteTypeCreate() {

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {<PageHeader
                        HeaderText="Create Special Waste Type"
                        Breadcrumb={[
                            { name: "Special Waste Types", navigate: "/waste-types" },
                            { name: "Create Special Waste Type", navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        {<WasteTypeCreateComponent />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasteTypeCreate;
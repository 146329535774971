import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import WasteTypeListComponent from "../../components/WasteType/WasteTypeList";
import { useParams } from "react-router-dom";


export default function WasteTypeList() {
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Special Waste Types"
                        Breadcrumb={[
                            { name: "Special Waste Types", navigate: "" },
                        ]}
                    />
                    <div className="row clearfix">
                        <WasteTypeListComponent />
                    </div>
                </div>
            </div>
        </div>
    );
}


import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import WasteTypeUpdateComponent from "../../components/WasteType/WasteTypeUpdate";
import Loader from "react-loader-spinner";
import { viewWasteTypeAction } from "../../actions";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

function WasteTypeUpdate({ viewWasteTypeAction }) {

    const params = useParams();
    const id = params.id;
    const [wasteType, setWasteTypeData] = useState(null);
   
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewWasteTypeAction({ id: id }, (err, response) => {
                if (!err) {
                    setWasteTypeData(response.data.data);
                }
            });
        }
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {wasteType && <PageHeader
                        HeaderText="Special Waste Type Update"
                        Breadcrumb={[
                            { name: "Special Waste Types", navigate: "/waste-types" },
                            { name: wasteType.title, navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix justify-content-center">
                        {wasteType ? <WasteTypeUpdateComponent wasteType={wasteType} />
                            : <div className="text-center" style={{marginTop: "50px"}}>
                                <Loader
                                    type="Bars"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    timeout={3000} //3 secs
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewWasteTypeAction })(WasteTypeUpdate)

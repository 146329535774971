import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createWasteTypeAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

const schema = yup.object().shape({
    title: yup.string().required("Title is required")
});

function WasteTypeCreate({ createWasteTypeAction }) {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            createWasteTypeAction(values, (err, response) => {
                if (!err) {
                    history.push(`/waste-types`);
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Special Waste Type
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                className={`form-control ${formik.errors.title && formik.touched.title && "parsley-error"
                                    }`}
                                value={formik.values.title}
                                name="title"
                                id="title"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.title && formik.touched.title &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.title}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createWasteTypeAction })(WasteTypeCreate);
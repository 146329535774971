import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { viewUserAction, createPayoutAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import constants from '../../config/constants';
import Viewer from 'react-viewer';
function AppUserView({ viewUserAction, createPayoutAction }) {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const id = params.id;
    const history = useHistory();
    useEffect(() => {
        viewUserAction({ id: id }, (err, response) => {
            if (!err) {
                setUserData(response.data.data);
            }
        });
    }, [id, viewUserAction]);

    const sendPayout = () => {
        setLoading(true);
        createPayoutAction({ collectorId: id }, (err, response) => {
            if (!err) {
                setLoading(false);
                history.push('/app-users')
            }
        });
    }

    function ImageViewer({ images }) {
        const [visible, setVisible] = React.useState(false);

        return (
            <div>
                <a style={{ color: "#007bff", cursor: "pointer" }} onClick={() => { setVisible(true); }}>View</a>
                <Viewer
                    visible={visible}
                    onClose={() => { setVisible(false); }}
                    images={images}
                />
            </div>
        );
    }

    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    userData ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {userData.firstName + ' ' + userData.lastName}
                                </h2>
                                {(userData.role === constants.ROLE.USER || userData.role === constants.ROLE.CORPORATE) && <button onClick={() => { history.push(`/app-users/assign-collectors/${userData._id}`) }} className="btn btn-outline-secondary" >Assign Collectors/Business Managers</button>}
                                {(userData.payableAmount > 0) && <button onClick={sendPayout} className="btn btn-outline-secondary" >{loading ? <i className='fa fa-spinner'></i> : `Pay Amount (£${userData.payableAmount})`}</button>}
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{userData.email}</td>

                                        </tr>
                                        <tr>
                                            <th>First Name:</th>
                                            <td>{userData.firstName}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Name:</th>
                                            <td>{userData.lastName}</td>
                                        </tr>
                                        <tr>
                                            <th>Role:</th>
                                            <td><span className="badge badge-primary">{userData.role}</span></td>
                                        </tr>
                                        <tr>
                                            <th>Status:</th>
                                            <td>{(userData.status === 0) ? <span className="badge badge-danger">Inactive</span> : <span className="badge badge-success">Active</span>}</td>
                                        </tr>
                                        <tr>
                                            <th>Address:</th>
                                            <td>{userData.address}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone Number:</th>
                                            <td>{userData.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Town:</th>
                                            <td>{userData.town}</td>
                                        </tr>
                                        <tr>
                                            <th>Postal Code:</th>
                                            <td>{userData.postalCode}</td>
                                        </tr>
                                        {
                                            userData.role === constants.ROLE.CORPORATE &&
                                            <>
                                                <tr>
                                                    <th>Business Name:</th> <td>{userData.businessName}</td>
                                                </tr>
                                            </>
                                        }

                                        {userData.role === constants.ROLE.INTRODUCER && <tr>
                                            <th>Introducer Code:</th>
                                            <td>{userData.introducerCode}</td>
                                        </tr>}
                                        {userData.role === constants.ROLE.COLLECTOR && <tr>
                                            <th>Collector Percentage:</th>
                                            <td>{userData.wasteCollectorPercentage}</td>
                                        </tr>}
                                        {userData.role === constants.ROLE.COLLECTOR && <tr>
                                            <th>Company:</th>
                                            <td>{userData.companyName}</td>
                                        </tr>}
                                        {((userData.role === constants.ROLE.COLLECTOR) && userData.payableAmount) ? <tr>
                                            <th>Payable Amount:</th>
                                            <td>{'£' + userData.payableAmount}</td>
                                        </tr> : null}
                                        {((userData.role === constants.ROLE.BUSINESS_MANAGER) && userData.payableAmount) ? <tr>
                                            <th>Payable Amount:</th>
                                            <td>{'£' + userData.payableAmount}</td>
                                        </tr> : null}
                                        {userData.role === constants.ROLE.USER && <>
                                            <tr>
                                                <th>Is Business ? :</th>
                                                <td>{userData.isBusiness ? "Yes" : "No"}</td>
                                            </tr>
                                            {
                                                userData.isBusiness && <>
                                                    <tr>
                                                        <th>Company Name:</th>
                                                        <td>{userData.businessName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Registration Number:</th>
                                                        <td>{userData.companyRegistrationNumber}</td>
                                                    </tr>

                                                </>
                                            }

                                        </>}
                                        {(((userData.role === constants.ROLE.COLLECTOR) || (userData.role === constants.ROLE.BUSINESS_MANAGER)) ?
                                            <>
                                                <tr>
                                                    <th>Bussiness Name :</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.businessName : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Trading Name :</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.tradingName : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Trading Address :</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.tradingAddress : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th> Town :</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.town : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th> Postal Code :</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.postalCode : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th> Waste Carrier License Number :</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.wasteCarrierLicenseNumber : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Company Registration Number:</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.companyRegistrationNumber : 'NA'}</td>
                                                </tr>

                                                <tr>
                                                    <th>Mobile Number:</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.mobileNumber : 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Telephone:</th>
                                                    <td>{userData.businessProfile ? userData.businessProfile.telephone : 'NA'}</td>
                                                </tr>
                                            </>
                                            : null)}
                                        {(((userData.role === constants.ROLE.COLLECTOR) || (userData.role === constants.ROLE.BUSINESS_MANAGER)) ?
                                            <>
                                                <tr>
                                                    <th>Driving License Front :</th>
                                                    <td> {(userData.certificates && userData.certificates.drivingLicenseFrontPath) ? <ImageViewer images={[{ src: userData.certificates.drivingLicenseFrontPath, alt: "" }]} /> : 'NA'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Driving License Reverse :</th>
                                                    <td> {(userData.certificates && userData.certificates.drivingLicenseReversePath) ? <ImageViewer images={[{ src: userData.certificates.drivingLicenseReversePath, alt: "" }]} /> : 'NA'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Liability Insurance Certificate Front:</th>
                                                    <td> {(userData.certificates && userData.certificates.liabilityInsuranceCertificateFrontPath) ? <ImageViewer images={[{ src: userData.certificates.liabilityInsuranceCertificateFrontPath, alt: "" }]} /> : 'NA'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Liability Insurance Certificate Reverse:</th>
                                                    <td> {(userData.certificates && userData.certificates.liabilityInsuranceCertificateReversePath) ? <ImageViewer images={[{ src: userData.certificates.liabilityInsuranceCertificateReversePath, alt: "" }]} /> : 'NA'}
                                                    </td>
                                                </tr>

                                                <>
                                                    <tr>
                                                        <th>Insurance Certificate Front:</th>
                                                        <td> {(userData.certificates && userData.certificates.insuranceCertificateFront) ? <ImageViewer images={[{ src: userData.certificates.insuranceCertificateFrontPath, alt: "" }]} /> : 'NA'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Insurance Certificate Reverse:</th>
                                                        <td> {(userData.certificates && userData.certificates.insuranceCertificateFront) ? <ImageViewer images={[{ src: userData.certificates.insuranceCertificateReversePath, alt: "" }]} /> : 'NA'}
                                                        </td>
                                                    </tr>
                                                </>
                                            </>
                                            : null)}

                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(userData.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </> :
                        <div className="text-center">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>

                }

            </div>
        </div>
    )
}

export default connect(null, { viewUserAction, createPayoutAction })(AppUserView)
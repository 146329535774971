import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createSettingAction, viewSettingAction } from '../../actions';
import { connect } from 'react-redux';

const schema = yup.object().shape({
    androidAppLink: yup.string().required("Android App Link is required"),
    appleAppLink: yup.string().required("IOS App Link is required"),
    androidWasteCollectorLink: yup.string().required("Android BusinessManager App Link is required"),
    iosWasteCollectorLink: yup.string().required("IOS BusinessManager App Link is required"),
});


function SettingCreate({ createSettingAction, viewSettingAction }) {

    const [settingData, setSettingData] = useState(null);



    const formik = useFormik({
        initialValues: {
            androidAppLink: '',
            appleAppLink: '',
            androidWasteCollectorLink: '',
            iosWasteCollectorLink: '',
            serviceCharge: '',
            minimumOrder: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            createSettingAction(values, (err, response) => {
                if (!err) {

                }
            })
        },
    });

    useEffect(() => {
        viewSettingAction({}, (err, response) => {
            if (!err && response.data.data) {
                formik.setValues({
                    androidAppLink: response.data.data.androidAppLink,
                    appleAppLink: response.data.data.appleAppLink,
                    androidWasteCollectorLink: response.data.data.androidWasteCollectorLink,
                    iosWasteCollectorLink: response.data.data.iosWasteCollectorLink,
                    serviceCharge: response.data.data.serviceCharge,
                    minimumOrder: response.data.data.minimumOrder
                })
                setSettingData(response.data.data);
            }
        });
    }, []);


    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Setting
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Android App Link</label>
                            <input
                                className={`form-control ${formik.errors.androidAppLink && formik.touched.androidAppLink && "parsley-error"
                                    }`}
                                value={formik.values.androidAppLink}
                                name="androidAppLink"
                                id="androidAppLink"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.androidAppLink && formik.touched.androidAppLink &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.androidAppLink}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>IOS App Link</label>
                            <input
                                className={`form-control ${formik.errors.appleAppLink && formik.touched.appleAppLink && "parsley-error"
                                    }`}
                                value={formik.values.appleAppLink}
                                name="appleAppLink"
                                id="appleAppLink"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.appleAppLink && formik.touched.appleAppLink &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.appleAppLink}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <label>IOS BusinessManager App Link</label>
                            <input
                                className={`form-control ${formik.errors.iosWasteCollectorLink && formik.touched.iosWasteCollectorLink && "parsley-error"
                                    }`}
                                value={formik.values.iosWasteCollectorLink}
                                name="iosWasteCollectorLink"
                                id="iosWasteCollectorLink"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.appleAppLink && formik.touched.iosWasteCollectorLink &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.iosWasteCollectorLink}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <label>Android BusinessManager App Link</label>
                            <input
                                className={`form-control ${formik.errors.androidWasteCollectorLink && formik.touched.androidWasteCollectorLink && "parsley-error"
                                    }`}
                                value={formik.values.androidWasteCollectorLink}
                                name="androidWasteCollectorLink"
                                id="androidWasteCollectorLink"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.androidWasteCollectorLink && formik.touched.androidWasteCollectorLink &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.androidWasteCollectorLink}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Service Charge</label>
                            <input
                                className={`form-control ${formik.errors.serviceCharge && formik.touched.serviceCharge && "parsley-error"
                                    }`}
                                value={formik.values.serviceCharge}
                                name="serviceCharge"
                                id="serviceCharge"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.serviceCharge && formik.touched.serviceCharge &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.serviceCharge}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Minimum Order</label>
                            <input
                                className={`form-control ${formik.errors.minimumOrder && formik.touched.minimumOrder && "parsley-error"
                                    }`}
                                value={formik.values.minimumOrder}
                                name="minimumOrder"
                                id="minimumOrder"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.minimumOrder && formik.touched.minimumOrder &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.minimumOrder}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createSettingAction, viewSettingAction })(SettingCreate);
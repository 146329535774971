import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import InvoiceViewComponent from "../../components/Invoice/InvoiceView";
import { useParams } from "react-router-dom";
import { viewInvoiceAction } from "../../actions";
import { connect } from 'react-redux';

function InvoiceView({ viewInvoiceAction }) {
    const params = useParams();
    const id = params.id;
    const [invoice, setInvoiceData] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewInvoiceAction({ id: id }, (err, response) => {
                if (!err) {
                    setInvoiceData(response.data.data);
                }
            });
        }
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {invoice && <PageHeader
                        HeaderText="Invoice Detail"
                        Breadcrumb={[
                            { name: "Invoices", navigate: "/invoices" },
                            { name: "Invoice Detail", navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <InvoiceViewComponent invoice={invoice} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewInvoiceAction })(InvoiceView)

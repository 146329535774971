import React from 'react';
import { FilePond } from 'react-filepond';
import axios from '../../config/axios';
import config from '../../config/constants';
class CustomFilePond extends React.Component {
    render() {
        let { modelType, ...restProps } = this.props;
        let token = localStorage.getItem('access_token');
        return <FilePond
            {...restProps}
            ref={this.props.forwordedRef}
            server={{
                url: config.API_URL,
                process: (fieldName, file, metadata, load, error, progress, abort) => {

                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    formData.append('modelType', modelType);
                    let url = config.BASE_URL + '/file/upload';
                    axios.post(url, formData, {
                        onUploadProgress: (e) => {
                            progress(e.lengthComputable, e.loaded, e.total);
                        }
                    }).then(function (response) {
                        load(response.data.id);
                    }, function (err) {
                        error("Something went wrong");
                    });
                },
                revert: (uniqueFileId, load, error) => {

                    let url = config.API_BASE_URL + '/file/delete?id=' + uniqueFileId
                    axios.delete(url).then(function (response) {
                        load();
                    }, function (err) {
                        error("Something went wrong");
                    });
                },
                remove: (uniqueFileId, load, error) => {

                    let url = config.API_BASE_URL + '/file/delete?id=' + uniqueFileId
                    axios.delete(url).then(function (response) {
                        load();
                    }, function (err) {
                        error("Something went wrong");
                    });
                },
                load: {
                    url: config.API_BASE_URL + '/file/get/',
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    withCredentials: false
                }
            }}
        ></FilePond >
    }
}

export default CustomFilePond;
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createSubCategoryItemAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import { useParams } from "react-router-dom";
const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    price: yup.number().required('Price is required'),
    categoryId: yup.string().required("Category is required"),
    description: yup.string().required('Description is required'),
    image: yup.mixed().required("Image is required").test("fileSize", "Image must be less than 5mb", (value) => {
        if (!value) return true // attachment is optional
        return value.size <= 1000000
    })
});


function SubCategoryCreate({ createSubCategoryItemAction, category }) {
    const params = useParams();
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            title: '',
            price: '',
            categoryId: category.categoryId._id,
            description: '',
            categoryItemId: category._id
        },
        validationSchema: schema,
        onSubmit: values => {
            console.log(values, "dfgdjsgfjdsgj");
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('price', values.price);
            formData.append('categoryId', values.categoryId);
            formData.append('categoryItemId', values.categoryItemId);
            formData.append('imageFile', values.image);
            createSubCategoryItemAction(formData, (err, response) => {
                if (!err) {
                    history.push(`/sub-category-item/${category.categoryId._id}/${category._id}`);
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Category Item
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                className={`form-control ${formik.errors.title && formik.touched.title && "parsley-error"
                                    }`}
                                value={formik.values.title}
                                name="title"
                                id="title"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.title && formik.touched.title &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.title}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <label>Price</label>
                            <input
                                className={`form-control ${formik.errors.price && formik.touched.price && "parsley-error"
                                    }`}
                                value={formik.values.price}
                                name="price"
                                id="price"
                                type="number"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.price && formik.touched.price &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.price}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea
                                className={`form-control ${formik.errors.description && formik.touched.description && "parsley-error"
                                    }`}
                                name="description"
                                id="description"
                                onChange={formik.handleChange}
                            >{formik.values.description}</textarea>
                            {
                                formik.errors.description && formik.touched.description &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.description}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <Dropzone onDrop={acceptedFiles => {
                                if (acceptedFiles.length > 0) {
                                    formik.setFieldValue('image', acceptedFiles[0]);
                                } else {
                                    formik.setFieldValue('image', '');
                                }
                            }}>
                                {({ getRootProps, getInputProps }) => (
                                    <div className="input-group">
                                        <div className="custom-file" {...getRootProps()}>
                                            <input className="custom-file-input" {...getInputProps()} />
                                            <label className="custom-file-label">{formik.values.image ? formik.values.image.name : "Choose file"}</label>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            {
                                formik.errors.image && formik.touched.image &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.image}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createSubCategoryItemAction })(SubCategoryCreate);
module.exports = {
    BASE_URL: 'https://api.trash2go.co.uk:8081/api/v1',
    // BASE_URL: 'https://api.miraclechd.co:3004/api/v1',
    // BASE_URL: 'http://localhost:8001/api/v1',
    ROLE: {
        SUPERADMIN: "SuperAdmin",
        ADMIN: "Admin",
        USER: "User",
        COLLECTOR: "WasteCollector",
        CORPORATE: "Corporate",
        INTRODUCER: 'Introducer',
        BUSINESS_MANAGER: 'BusinessManager'
    },
}
import React, { useEffect, useRef, useState } from 'react';
import { useFormik, Field } from 'formik';
import * as yup from 'yup';
import {
    createBookingAction, listCategoryAction, listCategoryItemAction,
    listWasteTypeCategoryAction, listWasteTypeAction, listUserAction
} from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import StepWizard from "react-step-wizard";
import Loader from "react-loader-spinner";
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'underscore';
import AsyncSelect from 'react-select/async';
import constants from '../../config/constants';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePond from '../Common/FilePond';

registerPlugin(FilePondPluginImagePreview);

function BookingInfo({ listUserAction, previousStep, createBookingAction, selectedItems }) {

    const [users, setUsers] = useState([]);
    const pondRef = useRef();
    const history = useHistory();

    const bookingInfoSchema = yup.object().shape({
        datetime: yup.string().required('Please select datetime'),
        asap: yup.boolean().required(),
        address: yup.string().required("Please select address"),
        postCode: yup.string().required('Postal Code is required'),
        notes: yup.string().notRequired(),
        userId: yup.string().required('User is required'),
        images: yup.array().of(yup.string()).min(2, 'Please upload atleast 2 images')
    });


    useEffect(() => {
        listUserAction({ page: 1, perPage: 20, filters: { role: { filterVal: constants.ROLE.USER } } }, (err, response) => {
            if (!err) {
                setUsers(response.data.data);
            }
        })
    }, []);

    const loadOptions = (inputValue, callback) => {
        listUserAction({ page: 1, perPage: 20, filters: { role: { filterVal: constants.ROLE.USER }, username: { filterVal: inputValue } } }, (err, response) => {
            if (!err) {
                setUsers(response.data.data);
                let data = response.data.data.map(item => { return { value: item._id, label: item.username }; });
                callback(data);
            }
        })
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue;
        return inputValue;
    };

    const formik = useFormik({
        initialValues: {
            datetime: '',
            asap: false,
            address: '',
            postCode: '',
            notes: '',
            userId: '',
            images: []
        },
        validationSchema: bookingInfoSchema,
        onSubmit: (values) => {
            const files = pondRef.current.getFiles();
            values.items = selectedItems;
            values.images = files.map(item => item.serverId);
            createBookingAction(values, (err, response) => {
                if (!err) {
                    history.push('/booking');
                }
            });
        },
    });


    return (
        <div className="row">
            <div className="col-12 form-group">
                <div className="list-group" className="tab-pane show active">
                    {
                        selectedItems.map((item, index) => {
                            return (
                                <a onClick={() => { }} key={index} className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                        <div>
                                            <h5 className="mb-0">{item.quantity} &times; {item.title}</h5>
                                            <p className="mb-0">{item.description}</p>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="pr-5"><h6 className="mb-0">£{item.price * item.quantity}</h6></div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
            <div className="col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label>User</label>
                        <AsyncSelect
                            cacheOptions
                            onChange={(value) => {
                                formik.setFieldValue('userId', value.value);
                            }}
                            name="userId"
                            loadOptions={loadOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onInputChange={handleInputChange}
                        />
                        {
                            formik.errors.userId && formik.touched.userId &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.userId}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Date & Time</label>
                        <Datetime onChange={(value) => {
                            formik.setFieldValue('datetime', value.toISOString());
                        }} />
                        {
                            formik.errors.datetime && formik.touched.datetime &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.datetime}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={(event) => { formik.setFieldValue('asap', event.target.checked) }} checked={formik.values.asap} name="asap" />
                            <label className="form-check-label">ASAP ?</label>
                        </div>
                        {formik.values.asap && <p>You will be charge 30% more of the total price</p>}
                        {
                            formik.errors.address && formik.touched.address &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.address}
                                </li>
                            </ul>
                        }
                    </div>

                    <div className="form-group">
                        <label>Collection Address</label>
                        <input
                            className={`form-control ${formik.errors.address && formik.touched.address && "parsley-error"
                                }`}
                            value={formik.values.address}
                            name="address"
                            id="address"
                            type="text"
                            onChange={formik.handleChange}
                        />
                        {
                            formik.errors.address && formik.touched.address &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.address}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Collection Postcode</label>
                        <input
                            className={`form-control ${formik.errors.postCode && formik.touched.postCode && "parsley-error"
                                }`}
                            value={formik.values.postCode}
                            name="postCode"
                            id="postCode"
                            type="text"
                            onChange={formik.handleChange}
                        />
                        {
                            formik.errors.postCode && formik.touched.postCode &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.postCode}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Notes</label>
                        <input
                            className={`form-control ${formik.errors.notes && formik.touched.notes && "parsley-error"
                                }`}
                            value={formik.values.notes}
                            name="notes"
                            id="notes"
                            type="text"
                            onChange={formik.handleChange}
                        />
                        {
                            formik.errors.notes && formik.touched.notes &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.notes}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Images</label>
                        <FilePond
                            files={formik.values.images}
                            onupdatefiles={(fileItems) => {
                                formik.setFieldValue('images', fileItems.map(fileItem => fileItem.file));
                            }}
                            allowMultiple={true}
                            maxFiles={5}
                            name="files"
                            forwordedRef={pondRef}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        {
                            formik.errors.notes && formik.touched.notes &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.notes}
                                </li>
                            </ul>
                        }
                    </div>
                </form>
                <div className="d-flex justify-content-center mt-3">
                    <div className="px-2"><button className="btn btn-green" onClick={previousStep}>prev</button></div>
                    <div className="px-2"><button className="btn btn-green" onClick={formik.handleSubmit}>Submit</button></div>
                </div>
            </div>
        </div>
    )
}

BookingInfo = connect(null, { listUserAction, createBookingAction })(BookingInfo)


function AdditionalInfoModal({ show, handleClose, currentItem, onItemSelect, listWasteTypeCategoryAction, listWasteTypeAction }) {

    const [categories, setCategories] = useState([]);
    const [wasteTypes, setWasteTypes] = useState([]);
    const history = useHistory();
    const additionalInfoSchema = yup.object().shape({
        wasteTypeCategoryIds: yup.array().of(yup.string().required("Please Waste Type")).min(1, "Please select at least one Waste Type"),
        weight: yup.number().required("Please enter weight").min(0, "Invalid weight"),
        wasteTypeId: yup.string().required("Please select a special waste type")
    });

    useEffect(() => {
        listWasteTypeCategoryAction({ page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setCategories(response.data.data);
            }
        });
        listWasteTypeAction({ page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setWasteTypes(response.data.data);
            }
        });
    }, []);




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            wasteTypeCategoryIds: [],
            weight: 0,
            wasteTypeId: ''
        },
        validationSchema: additionalInfoSchema,
        onSubmit: (values, { resetForm }) => {
            values.categoryItemId = currentItem._id;
            values.quantity = 1;
            values.title = currentItem.title;
            values.description = currentItem.description;
            values.price = currentItem.price;
            onItemSelect(values);
            resetForm({
                wasteTypeCategoryIds: [],
                weight: 0,
                wasteTypeId: ''
            });
            handleClose();
        },
    });

    const onHide = () => {
        formik.resetForm({
            wasteTypeCategoryIds: [],
            weight: 0,
            wasteTypeId: ''
        }); handleClose();
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Additional Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label>Waste Type</label>
                        <Select
                            defaultValue={[]}
                            isMulti
                            onChange={(value) => {
                                formik.setFieldValue('wasteTypeCategoryIds', value.map(item => item.value));
                            }}
                            name="colors"
                            options={categories.map(item => { return { value: item._id, label: item.title }; })}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        {
                            formik.errors.wasteTypeCategoryIds && formik.touched.wasteTypeCategoryIds &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.wasteTypeCategoryIds}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Weight</label>
                        <input
                            className={`form-control ${formik.errors.weight && formik.touched.weight && "parsley-error"
                                }`}
                            value={formik.values.weight}
                            name="weight"
                            id="weight"
                            type="number"
                            onChange={formik.handleChange}
                        />
                        {
                            formik.errors.weight && formik.touched.weight &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.weight}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Special Waste Types</label><br />
                        {
                            wasteTypes.map(item => {
                                return (
                                    <div key={item._id} className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" onChange={() => { formik.setFieldValue('wasteTypeId', item._id) }} name="wasteTypeId" value={item._id} />
                                        <label className="form-check-label">{item.title}</label>
                                    </div>
                                );
                            })
                        }
                        {
                            formik.errors.wasteTypeId && formik.touched.wasteTypeId &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.wasteTypeId}
                                </li>
                            </ul>
                        }
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={formik.handleSubmit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

AdditionalInfoModal = connect(null, { listWasteTypeCategoryAction, listWasteTypeAction })(AdditionalInfoModal)

function ChooseItem({ categories, listCategoryItemAction, onItemSelect, selectedItems, increaseQuantity, descreaseQuantity, previousStep, nextStep }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [category, setCategory] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [categoryItems, setCategoryItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const selectItem = (item) => {
        if (category.type === 0) {
            setCurrentItem(item);
            setShow(true);
        } else {
            let values = {};
            setCurrentItem(item);
            values.categoryItemId = item._id;
            values.quantity = 1;
            values.title = item.title;
            values.price = item.price;
            values.description = item.description;
            onItemSelect(values);
        }
    }


    const onTabChange = (index, item) => {
        setSelectedTab(index);
        setCategory(item);
        setLoading(true);
        listCategoryItemAction({ filters: { categoryId: { filterVal: item._id } }, page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setCategoryItems(response.data.data);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if (categories.length > 0) {
            setCategory(categories[0]);
            listCategoryItemAction({ filters: { categoryId: { filterVal: categories[0]._id } }, page: 1, perPage: 100 }, (err, response) => {
                if (!err) {
                    setCategoryItems(response.data.data);
                }
                setLoading(false);
            });
        }
    }, [categories.length]);

    return (
        <>
            <AdditionalInfoModal currentItem={currentItem} onItemSelect={onItemSelect} handleClose={handleClose} show={show} />
            <ul className="nav nav-tabs-new2" role="tablist">
                {
                    categories.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={(selectedTab === index) ? "nav-item mr-1 active" : "nav-item mr-1"}
                                id={"bacicTab2-" + index}
                                role="presentation"
                                onClick={() => {
                                    onTabChange(index, item);
                                }}
                            >
                                <a style={{ cursor: "pointer" }} className={(selectedTab === index) ? "nav-link active" : "nav-link"}>{item.title}</a>
                            </li>
                        );
                    })
                }
            </ul>
            <div className="tab-content">
                {(!loading) ? <div className="list-group" className="tab-pane show active">
                    {
                        categoryItems.map(item => {
                            let existingItem = _.find(selectedItems, item2 => item2.categoryItemId === item._id);
                            return (
                                <a onClick={() => { }} key={item._id} className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between">
                                        <div>
                                            <h5 className="mb-0">{item.title}</h5>
                                            <p className="mb-0">{item.description}</p>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="pr-5"><h6 className="mb-0">£{item.price}</h6></div>
                                                <div>
                                                    {
                                                        (!existingItem) ? <button className="btn btn-green" onClick={() => { selectItem(item) }}>Select</button> :
                                                            <div className="input-group plus-minus">
                                                                <span className="input-group-prepend">
                                                                    <button type="button" className="btn btn-green btn-number" onClick={() => { descreaseQuantity(existingItem) }} data-type="minus" data-field="quant[1]">
                                                                        <span className="fa fa-minus"></span>
                                                                    </button>
                                                                </span>
                                                                <input type="text" name="quant[1]" className="form-control input-number" value={existingItem.quantity} min="1" max="10" readOnly />
                                                                <span className="input-group-append">
                                                                    <button type="button" className="btn btn-green btn-number" onClick={() => increaseQuantity(existingItem)} data-type="plus" data-field="quant[1]">
                                                                        <span className="fa fa-plus"></span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </a>
                            )
                        })
                    }
                </div> : <div className="text-center mt-2">
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                    />
                </div>}
                <div className="d-flex justify-content-center mt-3">
                    <div className="px-2"><button className="btn btn-green" onClick={previousStep}>prev</button></div>
                    <div className="px-2"><button className="btn btn-green" disabled={(selectedItems.length === 0)} onClick={nextStep}>Next</button></div>
                </div>
            </div>
        </>
    );
}



function BookingCreate({ listCategoryAction, listCategoryItemAction }) {
    const [categories, setCategories] = useState([]);

    const [items, setItems] = useState([]);

    useEffect(() => {
        listCategoryAction({ page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setCategories(response.data.data);
            }
        })
    }, [])


    const itemSelect = (item) => {
        let newItems = [...items];
        newItems.push(item);
        setItems(newItems);
    }

    const increaseQuantity = (item) => {
        let newItems = items.map(item2 => {
            if (item.categoryItemId === item2.categoryItemId) {
                item2.quantity = item2.quantity + 1;
            }
            return item2;
        });
        setItems(newItems);
    }


    const descreaseQuantity = (item) => {
        if (item.quantity === 1) {
            let newItems = items.filter(item2 => {
                return item.categoryItemId !== item2.categoryItemId;
            });
            setItems(newItems);
        } else {
            let newItems = items.map(item2 => {
                if (item.categoryItemId === item2.categoryItemId) {
                    item2.quantity = item2.quantity - 1;
                }
                return item2;
            });
            setItems(newItems);
        }
    }



    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Booking
                    </h2>
                </div>
                <div className="body">
                    <StepWizard>
                        <ChooseItem categories={categories} onItemSelect={itemSelect} increaseQuantity={increaseQuantity} descreaseQuantity={descreaseQuantity} selectedItems={items} listCategoryItemAction={listCategoryItemAction} />
                        <BookingInfo selectedItems={items} />
                    </StepWizard>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createBookingAction, listCategoryAction, listCategoryItemAction })(BookingCreate);
import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/logo-white.svg";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { changePassword } from "../../actions";
import { reactLocalStorage } from 'reactjs-localstorage';
const schema = yup.object().shape({
    password: yup.string().required('password is required')
})

function ChangePassword({ changePassword }) {

    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            changePassword({ password: values.password, session: reactLocalStorage.get('session', '') }, (err, response) => {
                if (!err) {
                    history.push('/login');
                }
            })
        },
    });


    return (
        <div className="theme-cyan">
            <div >
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main">
                        <div className="auth-box">
                            <div className="top">
                                <img src={Logo} alt="Trash2Go" style={{ height: "40px", margin: "10px" }} />
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Change Password</p>
                                </div>
                                <div className="body">
                                    <p>Please enter the new password.</p>
                                    <form onSubmit={formik.handleSubmit} className="form-auth-small ng-untouched ng-pristine ng-valid">
                                        <div className="form-group">
                                            <input className="form-control" placeholder="Password" id="password" onChange={formik.handleChange} name="password" value={formik.values.password} type="password" />
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">
                                            Change Password
                                        </button>
                                        <div className="bottom">
                                            <span className="helper-text">Know your password? <a href="login">Login</a></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ loginReducer }) => ({
});

export default connect(mapStateToProps, {
    changePassword
})(ChangePassword);

import React, { useState } from "react";
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { listInvoiceAction } from '../../actions';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';

function InvoiceList({ listInvoiceAction }) {
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [total, setTotal] = useState(0);
    const [tableState, setTableState] = useState(null);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(20);
    const history = useHistory();

    const loadData = (data) => {
        setLoading(true);
        listInvoiceAction(data, (err, response) => {
            if (!err) {
                setProperties(response.data.data);
                setTotal(response.data.total);
            }
            setLoading(false);
        });
    }

    const columns = [
        {
            dataField: 'bookingId.bookingToken',
            text: 'Order ID',
            filter: false,
            sort: false
        },
        // {
        //     dataField: 'userLastName',
        //     text: 'Last Name',
        //     filter: textFilter(),
        //     formatter: (cell, row) => {
        //         return row.userId ? row.userId.lastName : '';
        //     }
        // },
        {
            dataField: 'invoiceID',
            text: 'Invoice ID',
            filter: textFilter()
        },
        {
            dataField: 'total',
            text: 'Total',
            formatter: (cell, row) => {
                return '£' + row.total;
            }
        },
        {
            dataField: 'totalTax',
            text: 'Tax',
            formatter: (cell, row) => {
                return '£' + row.totalTax;
            }
        },
        {
            dataField: 'invoiceNumber',
            text: 'Invoice Number'
        },
        // {
        //     dataField: 'type',
        //     text: 'Type'
        // },
        {
            dataField: 'status',
            text: 'Status',
            filter: selectFilter({
                options: {
                    "AUTHORISED": "AUTHORISED",
                    "PAID": "PAID"
                }
            })
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            formatter: (cell, row) => {
                return moment(row.createdAt).format('YYYY-MM-DD hh:mm a')
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: (cell, row) => {
                return <>
                    <button onClick={() => { history.push(`/invoice/view/${row._id}`) }} className="btn btn-outline-secondary mr-1"><i className="icon-eye"></i></button>
                </>
            },
        },
    ];

    const onTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        let data = { ...newState };
        data.perPage = newState.sizePerPage;
        delete data.data;
        setTableState(data);
        loadData(data);
    }

    return (
        <div className="col-lg-12">
            <div className="card">
                {/* <div className="header text-right pb-0">
                    <Link to="/invoice/create" className="btn btn-primary btn-sm">Create</Link>
                </div> */}
                <div className="body table-responsive">
                    <BootstrapTable bootstrap4={true}
                        keyField='_id'
                        loading={loading}
                        data={properties}
                        columns={columns}
                        headerWrapperClasses="thead-light"
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize: total,
                            withFirstAndLast: true,
                            alwaysShowAllBtns: true
                        })}
                        filter={filterFactory()}
                        filterPosition="top"
                        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
                        overlay={overlayFactory()}
                        onTableChange={onTableChange}
                        remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: false
                        }}
                    />
                </div>
            </div>
        </div>
    );
}


export default connect(null, {
    listInvoiceAction
})(InvoiceList)
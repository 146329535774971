import axios from '../config/axios';
import { BASE_URL } from '../config/constants';
import { handleError } from './LoginAction';
import { SHOW_NOTIFICATION } from './UIElementsAction';
export const listVehicleAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/manager/vehicle/list', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const viewVehicleAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/manager/vehicle/view', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const createVehicleAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/manager/vehicle/create', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const updateVehicleAction = (id, params, cb) => (dispatch) => {
    axios.put(BASE_URL + `/manager/vehicle/update?id=${id}`, params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const deleteVehicleAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/manager/vehicle/delete', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}
import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/logo-white.svg";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { verifyOtp, notifyOnClose } from "../../actions";
import { reactLocalStorage } from 'reactjs-localstorage';
import { Toast } from "react-bootstrap";
const schema = yup.object().shape({
    otp: yup.string().required('otp is required')
})

function VerifyOtp({ verifyOtp, notifyData, notifyOnClose }) {

    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            verifyOtp({ otp: values.otp, session: reactLocalStorage.get('session', '') }, (err, response) => {
                if (!err) {
                    reactLocalStorage.set('session', response.data.data.session);
                    history.push('/change-password');
                }
            })
        },
    });


    return (
        <div className="theme-cyan">
            <Toast
                id="toast-container"
                show={true}
                className={`toast-top-right toast-container`}
                style={{ background: "transparent" }}
                onClose={() => { }}
            >
                {notifyData.map((data, i) => {
                    if (data.position === "top-right") {
                        setTimeout(() => {
                            notifyOnClose(i);
                        }, 10000);
                        return (
                            <Toast.Body
                                key={"keys" + i}
                                className={`toast-${data.type} mb-10 ml-0`}
                                style={{ opacity: 1 }}
                            >
                                <strong className="mr-auto"> {data.dialogText} </strong>
                                <button
                                    className="toast-close-button"
                                    onClick={() => {
                                        notifyOnClose(i);
                                    }}
                                >
                                    x
                                </button>
                            </Toast.Body>
                        );
                    }
                })}
            </Toast>
            <div >
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main">
                        <div className="auth-box">
                            <div className="top">
                                <img src={Logo} alt="Trash2Go" style={{ height: "40px", margin: "10px" }} />
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Verify Otp</p>
                                </div>
                                <div className="body">
                                    <p>Please enter the otp you recived on your email address.</p>
                                    <form onSubmit={formik.handleSubmit} className="form-auth-small ng-untouched ng-pristine ng-valid">
                                        <div className="form-group">
                                            <input className="form-control" placeholder="OTP" id="otp" onChange={formik.handleChange} name="otp" value={formik.values.otp} type="text" />
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">
                                            Verify
                                        </button>
                                        <div className="bottom">
                                            <span className="helper-text">Know your password? <a href="login">Login</a></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = ({ loginReducer, UIElementsReducer }) => {
    const { notifyData } = UIElementsReducer;
    return { notifyData };
};

export default connect(mapStateToProps, {
    verifyOtp,
    notifyOnClose
})(VerifyOtp);

import React from "react";
import PageHeader from "../../components/PageHeader";
import NewsCreateComponent from "../../components/News/NewsCreate";

function NewsCreate() {


    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="News Create"
                        Breadcrumb={[
                            { name: "News", navigate: "/news" },
                            { name: "News Create", navigate: "" },
                        ]}
                    />
                    <div className="row clearfix">
                        <NewsCreateComponent />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default NewsCreate;

import axios from '../config/axios';
import { BASE_URL } from '../config/constants';
import { handleError } from './LoginAction';
import { SHOW_NOTIFICATION } from './UIElementsAction';
export const listUserAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/app-user/list', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const approveUserAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/app-user/approve', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const rejectUserAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/app-user/reject', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const forgotPassword = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/user/forgotPassword', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const verifyOtp = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/user/veriftOtp', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const changePassword = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/user/changePassword', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const createAppUserAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/app-user/create', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const viewUserAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/app-user/view', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const payoutAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/payout/create', params).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const deleteUserAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/app-user/delete', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const updateUserAction = (id, params, cb) => (dispatch) => {
    axios.put(BASE_URL + `/admin/app-user/update?id=${id}`, params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const assignCollectorsAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + `/admin/app-user/assign-collectors`, params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}
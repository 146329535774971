import React from "react";
import PageHeader from "../../components/PageHeader";
import CouponViewComponent from "../../components/Coupon/CouponView";

class CouponView extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div
                style={{ flex: 1 }}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="Coupon Detail"
                            Breadcrumb={[
                                { name: "Coupons", navigate: "/coupons" },
                                { name: "Coupon Detail", navigate: "" },
                            ]}
                        />
                        <div className="row clearfix">
                            <CouponViewComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CouponView;

import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createVehicleAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

const schema = yup.object().shape({
    make: yup.string().required("Make is required"),
    model: yup.string().required("Model is required"),
    registrationNumber: yup.string().required("Registration No. is required"),
    capacity: yup.number().required("Capacity is required"),
    numberPlate: yup.string().required("Number plate is required"),
});


function VehicleCreate({ createVehicleAction }) {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            make: '',
            model: '',
            registrationNumber: '',
            capacity: '',
            numberPlate: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            createVehicleAction(values, (err, response) => {
                if (!err) {
                    history.push('/vehicle');
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Vehicle
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Make</label>
                            <input
                                className={`form-control ${formik.errors.make && formik.touched.make && "parsley-error"
                                    }`}
                                value={formik.values.make}
                                name="make"
                                id="make"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.make && formik.touched.make &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.make}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Model</label>
                            <input
                                className={`form-control ${formik.errors.model && formik.touched.model && "parsley-error"
                                    }`}
                                value={formik.values.model}
                                name="model"
                                id="model"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.model && formik.touched.model &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.model}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Registraton Number</label>
                            <input
                                className={`form-control ${formik.errors.registrationNumber && formik.touched.registrationNumber && "parsley-error"
                                    }`}
                                value={formik.values.registrationNumber}
                                name="registrationNumber"
                                id="registrationNumber"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.registrationNumber && formik.touched.registrationNumber &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.registrationNumber}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Capacity</label>
                            <input
                                type='number'
                                className={`form-control ${formik.errors.capacity && formik.touched.capacity && "parsley-error"
                                    }`}
                                value={formik.values.capacity}
                                name="capacity"
                                id="capacity"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.capacity && formik.touched.capacity &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.capacity}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Number Plate</label>
                            <input
                                className={`form-control ${formik.errors.numberPlate && formik.touched.numberPlate && "parsley-error"
                                    }`}
                                value={formik.values.numberPlate}
                                name="numberPlate"
                                id="numberPlate"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.numberPlate && formik.touched.numberPlate &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.numberPlate}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createVehicleAction })(VehicleCreate);
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import imageuser from "../../assets/images/user.png";
import Dropzone from 'react-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { profileAction } from '../../actions';

const schema = yup.object().shape({
	firstName: yup.string().required("First Name is required"),
	lastName: yup.string().required("Last Name is required"),
	email: yup.string().required("Email is required"),
	phoneNumber: yup.string().required('Phone is required'),
	password: yup.string().notRequired(),
	confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
	image: yup.mixed().required("Image is required").test("fileSize", "Image must be less than 5mb", (value) => {
		if (!value) return true // attachment is optional
		return value.size <= 1000000
	})
});

function ProfileV1Setting({ profileAction }) {
	const [profile, setProfile] = useState(null);
	let inputRef = useRef();
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			password: '',
			confirmPassword: '',
			image: ''
		},
		validationSchema: schema,
		onSubmit: values => {

		},
	});

	useEffect(() => {
		profileAction((err, response) => {
			if (!err) {
				setProfile(response.data.data);
			}
		})
	}, []);

	return (
		<div>
			<form onSubmit={formik.handleSubmit}>
				<div className="body">
					<h6>Profile Photo</h6>
					<div className="media">
						<div className="media-left m-r-15" >
							<img
								alt="User"
								width="150px"
								className="user-photo media-object"
								src={imageuser}
							/>
						</div>
						<div className="media-body">
							<Dropzone onDrop={acceptedFiles => {
								if (acceptedFiles.length > 0) {
									formik.setFieldValue('image', acceptedFiles[0]);
								} else {
									formik.setFieldValue('image', '');
								}
							}}>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps()}>
										<p>
											Upload your photo. <br />
											<em>Image should be at least 140px x 140px</em>
										</p>
										<button
											className="btn btn-default-dark"
											id="btn-upload-photo"
											type="button"
										>
											Upload Photo
										</button>
										<input ref={inputRef} className="sr-only" id="filePhoto" type="file" {...getInputProps()} />
									</div>
								)}
							</Dropzone>

						</div>
					</div>
				</div>
				<div className="body">
					<h6>Basic Information</h6>
					<div className="row clearfix">
						<div className="col-md-6">
							<div className="form-group">
								<label>Email</label>
								<input
									className={`form-control ${formik.errors.email && formik.touched.email && "parsley-error"
										}`}
									value={formik.values.email}
									name="email"
									id="email"
									onChange={formik.handleChange}
								/>
								{
									formik.errors.email && formik.touched.email &&
									<ul className="parsley-errors-list filled" id="parsley-id-29">
										<li className="parsley-required">
											{formik.errors.email}
										</li>
									</ul>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>First Name</label>
								<input
									className={`form-control ${formik.errors.firstName && formik.touched.firstName && "parsley-error"
										}`}
									value={formik.values.firstName}
									name="firstName"
									id="firstName"
									onChange={formik.handleChange}
								/>
								{
									formik.errors.firstName && formik.touched.firstName &&
									<ul className="parsley-errors-list filled" id="parsley-id-29">
										<li className="parsley-required">
											{formik.errors.firstName}
										</li>
									</ul>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>Last Name</label>
								<input
									className={`form-control ${formik.errors.lastName && formik.touched.lastName && "parsley-error"
										}`}
									value={formik.values.lastName}
									name="lastName"
									id="lastName"
									onChange={formik.handleChange}
								/>
								{
									formik.errors.lastName && formik.touched.lastName &&
									<ul className="parsley-errors-list filled" id="parsley-id-29">
										<li className="parsley-required">
											{formik.errors.lastName}
										</li>
									</ul>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>Phone Number</label>
								<input
									className={`form-control ${formik.errors.phoneNumber && formik.touched.phoneNumber && "parsley-error"
										}`}
									value={formik.values.phoneNumber}
									name="lastName"
									id="lastName"
									onChange={formik.handleChange}
								/>
								{
									formik.errors.phoneNumber && formik.touched.phoneNumber &&
									<ul className="parsley-errors-list filled" id="parsley-id-29">
										<li className="parsley-required">
											{formik.errors.phoneNumber}
										</li>
									</ul>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>Password</label>
								<input
									className={`form-control ${formik.errors.password && formik.touched.password && "parsley-error"
										}`}
									value={formik.values.password}
									name="password"
									id="password"
									type="password"
									onChange={formik.handleChange}
								/>
								{
									formik.errors.password && formik.touched.password &&
									<ul className="parsley-errors-list filled" id="parsley-id-29">
										<li className="parsley-required">
											{formik.errors.password}
										</li>
									</ul>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>Confirm Password</label>
								<input
									className={`form-control ${formik.errors.confirmPassword && formik.touched.confirmPassword && "parsley-error"
										}`}
									value={formik.values.confirmPassword}
									name="confirmPassword"
									id="confirmPassword"
									type="password"
									onChange={formik.handleChange}
								/>
								{
									formik.errors.confirmPassword && formik.touched.confirmPassword &&
									<ul className="parsley-errors-list filled" id="parsley-id-29">
										<li className="parsley-required">
											{formik.errors.confirmPassword}
										</li>
									</ul>
								}
							</div>
						</div>
					</div>
					<button className="btn btn-primary" type="button" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
						Update
					</button>
				</div>
			</form>
		</div>

	);

}

const mapStateToProps = (null, { profileAction });

export default connect(mapStateToProps, {})(ProfileV1Setting);

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { viewUserAction, listUserAction, assignCollectorsAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import constants from '../../config/constants';
import AsyncSelect from 'react-select/async';
import { useParams } from 'react-router-dom';

const schema = yup.object().shape({
    collectors: yup.array().of(yup.object().required()).required("Collectors are required"),
});


function AssignCollectors({ viewUserAction, listUserAction, assignCollectorsAction }) {
    const history = useHistory();
    const [user, setAppUserData] = useState(null);
    const params = useParams();
    const id = params.id;

    const loadOptions = (inputValue, callback) => {
        listUserAction({ page: 1, perPage: 20, filters: { role: { filterVal: [constants.ROLE.COLLECTOR, constants.ROLE.BUSINESS_MANAGER] }, username: { filterVal: inputValue } } }, (err, response) => {
            if (!err) {
                let data = response.data.data.map(item => { return { value: item._id, label: item.username }; });
                callback(data);
            }
        })
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue;
        return inputValue;
    };

    const formik = useFormik({
        initialValues: {
            collectors: [],
        },
        validationSchema: schema,
        onSubmit: values => {
            values.id = id;
            values.collectors = values.collectors.map(item => item.value)
            assignCollectorsAction(values, (err, response) => {
                if (!err) {
                    history.push(`/app-users/view/${id}`);
                }
            })
        },
    });

    useEffect(() => {
        viewUserAction({ id: id }, (err, response) => {
            if (!err) {
                setAppUserData(response.data.data);
                let userData = response.data.data;
                formik.setValues({
                    collectors: userData.collectors.map(item => { return { value: item._id, label: item.username } })
                });
            }
        });
    }, [id]);

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Assign Collectors
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Collectors/Business Managers</label>
                                    <AsyncSelect
                                        cacheOptions
                                        isMulti
                                        onChange={(value) => {
                                            formik.setFieldValue('collectors', value);
                                        }}
                                        value={formik.values.collectors}
                                        name="collectors"
                                        loadOptions={loadOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onInputChange={handleInputChange}
                                    />
                                    {
                                        formik.errors.collectors && formik.touched.collectors &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.collectors}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { viewUserAction, listUserAction, assignCollectorsAction })(AssignCollectors);
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { assignBookingAction, listUserAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import AsyncSelect from 'react-select/async';
import { useFormik } from 'formik';

const assignSchema = yup.object().shape({
    collectorId: yup.string().required('Please select a collector')
})

function BookingAssign({ booking, assignBookingAction, listUserAction }) {
    const history = useHistory();
    const assign = useCallback((collectorId) => {
        assignBookingAction({ id: booking.id, collectorId }, (err, response) => {
            if (!err) {
                history.push(`/booking`)
            }
        })
    }, [booking]);

    const handleInputChange = useCallback((newValue) => {
        const inputValue = newValue;
        return inputValue;
    });

    const loadOptions = useCallback((inputValue, callback) => {
        listUserAction({ page: 1, perPage: 20, filters: { username: { filterVal: inputValue } } }, (err, response) => {
            if (!err) {
                let data = response.data.data.map(item => { return { value: item._id, label: item.username, role: item.role, corporateDiscount: item.corporateDiscount ? item.corporateDiscount : 0 }; });
                callback(data);
            }
        })
    });

    const formik = useFormik({
        initialValues: {
            collectorId: '',
        },
        validationSchema: assignSchema,
        onSubmit: (values) => {
            assign(values.collectorId);
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    booking ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {booking.bookingToken}
                                </h2>
                            </div>
                            <div className='body'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <label>Select Collector</label>
                                        <AsyncSelect
                                            cacheOptions
                                            onChange={(value) => {
                                                formik.setFieldValue('collectorId', value.value);
                                            }}
                                            name="collectorId"
                                            loadOptions={loadOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onInputChange={handleInputChange}
                                        />
                                        {
                                            formik.errors.collectorId && formik.touched.collectorId &&
                                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                <li className="parsley-required">
                                                    {formik.errors.collectorId}
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                    <div ><button className="btn btn-green" onClick={formik.handleSubmit}>Submit</button></div>
                                </form>
                            </div>

                        </> :
                        <div className="text-center mt-2">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>
                }

            </div>
        </div>
    )
}

export default connect(null, { assignBookingAction, listUserAction })(BookingAssign)
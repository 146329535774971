import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import SubCategoryItemListComponent from "../../components/SubCategoryItem/SubCategoryItemList";
import { useParams } from "react-router-dom";


export default function CategoryList() {
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Sub Category Items"
                        Breadcrumb={[
                            { name: "Categories Items", navigate: "/category-item/" },
                            { name: "Category Items View", navigate: `/category-item/view/${params.categoryItemId}` },
                            { name: "Sub Category Items", navigate: "" },
                        ]}
                    />
                    <div className="row clearfix">
                        <SubCategoryItemListComponent />
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import CategoryItemViewComponent from "../../components/CategoryItem/CategoryItemView";
import { useParams } from "react-router-dom";
import { viewCategoryItemAction } from "../../actions";
import { connect } from 'react-redux';

 function CategoryItemView( { viewCategoryItemAction } ) {
    const params = useParams();
    const id = params.id;
    const [categoryItem, setCategoryItemData] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewCategoryItemAction({ id: id }, (err, response) => {
                if (!err) {
                    setCategoryItemData(response.data.data);
                }
            });
        }
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {categoryItem && <PageHeader
                        HeaderText="Category Item Detail"
                        Breadcrumb={[
                            { name: "Categories", navigate: "/categories" },
                            { name: 'Category Items', navigate: `/category-item/${categoryItem.categoryId._id}` },
                            { name: categoryItem.title, navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <CategoryItemViewComponent categoryItem={categoryItem}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewCategoryItemAction })(CategoryItemView)

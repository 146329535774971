import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import constants from "../config/constants";
import { SHOW_NOTIFICATION } from "./UIElementsAction";

export const UPDATE_EMAIL = "loginReducer/UPDATE_EMAIL";
export const UPDATE_PASSWORD = "loginReducer/UPDATE_PASSWORD";
export const ON_LOGGEDIN = "loginReducer/ON_LOGGEDIN";

export const handleError = (dispatch, err) => {
	dispatch({
		type: SHOW_NOTIFICATION,
		payload: {
			type: "error",
			position: "top-right",
			dialogText: err.response ? err.response.data.message : "Something went wrong",
			show: true,
		},
	});
}

export const updateEmail = (val) => (disptch) => {
	disptch({
		type: UPDATE_EMAIL,
		payload: val,
	});
};

export const updatePassword = (val) => (disptch) => {
	disptch({
		type: UPDATE_PASSWORD,
		payload: val,
	});
};

export const profileAction = (cb) => (dispatch) => {
	axios.get(constants.BASE_URL + '/admin/user/profile').then(response => {
		if (cb) {
			return cb(null, response);
		}
	}).catch(err => {
		handleError(dispatch, err);
		if (cb) {
			return cb(err);
		}
	});
}

export const loginAction = (data, cb) => (dispatch) => {
	axios.post(constants.BASE_URL + '/admin/user/login', data).then(response => {
		reactLocalStorage.set('access_token', response.data.token);
		reactLocalStorage.setObject('user', response.data.data);
		dispatch({
			type: ON_LOGGEDIN,
			payload: response.data.data
		});
		dispatch({
			type: SHOW_NOTIFICATION,
			payload: {
				type: "success",
				position: "top-right",
				dialogText: "Logged in successfully",
				show: true,
			},
		});
		if (cb) {
			return cb(null, response);
		}
	}).catch(err => {
		handleError(dispatch, err);
		if (cb) {
			return cb(err);
		}
	});
}

export const onLoggedin = (val) => (disptch) => {
	disptch({
		type: ON_LOGGEDIN,
		payload: val,
	});
};

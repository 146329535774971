import axios from 'axios';
import CONSTANT from './constants';
import { reactLocalStorage } from 'reactjs-localstorage';
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.baseURL = CONSTANT.BASE_URL;
    config.headers = {
        'content-type': 'application/json',
    }
    const userData = reactLocalStorage.getObject('userData', null);

    if (localStorage.getItem('access_token')) {
        config.headers.Authorization = localStorage.getItem('access_token');
    }

    return config;
});


export default axios;
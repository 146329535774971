import React from "react";
import PageHeader from "../../components/PageHeader";
import WasteTypeCategoryCreateComponent from "../../components/WasteTypeCategory/WasteTypeCategoryCreate";

function WasteTypeCategoryCreate() {

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {<PageHeader
                        HeaderText="Create Waste Type"
                        Breadcrumb={[
                            { name: "Waste Types", navigate: "/waste-type-category" },
                            { name: "Create Waste Type", navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        {<WasteTypeCategoryCreateComponent />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasteTypeCategoryCreate;
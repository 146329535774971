import React from "react";
import PageHeader from "../../components/PageHeader";
import CmsCreateComponent from "../../components/CmsPages/CmsPageCreate";

class CmsCreate extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div
                style={{ flex: 1 }}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="CMS Create"
                            Breadcrumb={[
                                { name: "CMS", navigate: "/cms-pages" },
                                { name: "CMS Create", navigate: "" },
                            ]}
                        />
                        <div className="row clearfix">
                            <CmsCreateComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CmsCreate;

import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./screens/Login";
import dashboard from "./screens/Dashbord/Dashbord";
import AppUserList from "./screens/AppUsers/AppUserList";
import demographic from "./screens/Dashbord/Demographic";
import ioT from "./screens/Dashbord/IoT";
import NavbarMenu from "./components/NavbarMenu";
import appInbox from "./screens/App/Inbox";
import appChat from "./screens/App/Chat";
import appCalendar from "./screens/App/Calendar";
import appContact from "./screens/App/Contact";
import appTaskbar from "./screens/App/Taskbar";
import filemanagerdashboard from "./screens/FileManager/Dashboard";
import filedocuments from "./screens/FileManager/Documents";
import filemedia from "./screens/FileManager/Media";
import fileimages from "./screens/FileManager/Images";
import blognewPost from "./screens/Blog/NewPost";
import blogdetails from "./screens/Blog/BlogDetails";
import bloglist from "./screens/Blog/BlogList";
import uitypography from "./screens/UIElements/Typography";
import uitabs from "./screens/UIElements/Tabs";
import uibuttons from "./screens/UIElements/Button";
import bootstrapui from "./screens/UIElements/BootstrapUI";
import uiicons from "./screens/UIElements/Icons";
import uinotifications from "./screens/UIElements/Notifications";
import uicolors from "./screens/UIElements/Colors";
import uilistgroup from "./screens/UIElements/ListGroup";
import uimediaobject from "./screens/UIElements/MediaObject";
import uimodal from "./screens/UIElements/Modals";
import uiprogressbar from "./screens/UIElements/ProgressBar";
import widgetsdata from "./screens/Widgets/Data";
import widgetsweather from "./screens/Widgets/Weather";
import widgetsblog from "./screens/Widgets/Blog";
import widgetsecommers from "./screens/Widgets/ECommers";
import registration from "./screens/Auth/Registration";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Auth/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import blankpage from "./screens/Pages/BlankPage";
import profilev1page from "./screens/Pages/ProfileV1";
import profilev2page from "./screens/Pages/ProfileV2";
import imagegalleryprofile from "./screens/Pages/ImageGallery";
import timeline from "./screens/Pages/TimeLine";
import pricing from "./screens/Pages/Pricing";
import invoices from "./screens/Pages/Invoices";
import invoicesv2 from "./screens/Pages/InvoicesV2";
import searchresult from "./screens/Pages/SearchResults";
import helperclass from "./screens/Pages/HelperClass";
import teamsboard from "./screens/Pages/TeamsBoard";
import projectslist from "./screens/Pages/ProjectsList";
import maintanance from "./screens/Pages/Maintanance";
import testimonials from "./screens/Pages/Testimonials";
import faqs from "./screens/Pages/Faqs";
import formvalidation from "./screens/Forms/FormValidation";
import basicelements from "./screens/Forms/BasicElements";
import tablenormal from "./screens/Tables/TableNormal";
import echart from "./screens/Charts/Echart";
import leafletmap from "./screens/Maps/GoogleMaps";
import AppUserView from "./screens/AppUsers/AppUserView";
import CreateAppUser from "./screens/AppUsers/CreateAppUser";
import CategoryList from "./screens/Category/CategoryList";
import CategoryView from "./screens/Category/CategoryView";
import CategoryCreate from "./screens/Category/CreateCategory";
import CategoryUpdate from "./screens/Category/UpdateCategory";

import VehicleList from "./screens/Vehicle/VehicleList";
import VehicleView from "./screens/Vehicle/VehicleView";
import VehicleCreate from "./screens/Vehicle/CreateVehicle";
import VehicleUpdate from "./screens/Vehicle/UpdateVehicle";

import CategoryItemList from "./screens/CategoryItems/CategoryItemList";
import CategoryItemView from "./screens/CategoryItems/CategoryItemView";
import CreateCategoryItem from "./screens/CategoryItems/CreateCategoryItem";
import UpdateCategoryItem from "./screens/CategoryItems/UpdateCategoryItem";
import WasteTypeList from "./screens/WasteType/WasteTypeList";
import WasteTypeView from "./screens/WasteType/WasteTypeView";
import UpdateWasteType from "./screens/WasteType/UpdateWasteType";
import CreateWasteType from "./screens/WasteType/CreateWasteType";
import WasteTypeCategoryList from "./screens/WasteTypeCategory/WasteTypeCategoryList";
import WasteTypeCategoryView from "./screens/WasteTypeCategory/WasteTypeCategoryView";
import UpdateWasteTypeCategory from "./screens/WasteTypeCategory/UpdateWasteTypeCategory";
import CreateWasteTypeCategory from "./screens/WasteTypeCategory/CreateWasteTypeCategory";
import BookingList from "./screens/Booking/BookingList";
import BookingView from "./screens/Booking/BookingView";
import BookingCreate from "./screens/Booking/CreateBooking";
import AppUserUpdate from "./screens/AppUsers/UpdateAppUser";
import PrivateRoute from "./components/Common/PrivateRoute";
import InvoiceList from "./screens/Invoice/InvoiceList";
import InvoiceCreate from "./components/Invoice/InvoiceCreate";
import InvoiceView from "./screens/Invoice/InvoiceView";
import TeamMemberList from "./screens/TeamMembers/TeamMemberList";
import TeamMemberCreate from "./screens/TeamMembers/CreateTeamMember";
import TeamMemberView from "./screens/TeamMembers/TeamMemberView";
import TeamMemberUpdate from "./screens/TeamMembers/UpdateTeamMember";
import CouponList from "./screens/Coupon/CouponList";
import CouponCreate from "./screens/Coupon/CreateCoupon";
import CouponView from "./screens/Coupon/CouponView";
import CouponUpdate from "./screens/Coupon/UpdateCoupon";
import AssignCollectors from "./screens/AppUsers/AssignCollectors";
import PayoutList from "./screens/Payout/PayoutList";
import PayoutView from "./screens/Payout/PayoutView";
import SubCategoryItemList from "./screens/SubCategoryItems/SubCategoryItemList";
import SubCategoryItemCreate from "./screens/SubCategoryItems/CreateSubCategory";
import SubCategoryItemView from "./screens/SubCategoryItems/SubCategoryItemView";
import SubCategoryItemUpdate from "./screens/SubCategoryItems/UpdateSubCategoryItem";
import SettingCreate from './screens/Setting/CreateSetting';

import CmsPagesList from "./screens/CmsPages/CmspagesList";
import CmsCreatePages from './screens/CmsPages/CmsCreate';
import CmsUpdatePages from "./screens/CmsPages/CmsUpdate";
import CmsViewPages from './screens/CmsPages/CmsView';
import FaqList from "./screens/FAQ/FaqList";
import FaqCreate from './screens/FAQ/FaqCreate';
import FaqUpdate from './screens/FAQ/FaqUpdate';
import NewsList from "./screens/News/NewsList";
import RequestedQuotesList from "./screens/RequestedQuotes/RequestedQuotesList";
import RequestedQuotesView from "./screens/RequestedQuotes/RequestedQuotesView";
import AbandonOrdersList from "./screens/AbandonOrders/AbandonOrdersList";
import AbandonOrdersView from "./screens/AbandonOrders/AbandonOrdersView";
import NewsCreate from './screens/News/NewsCreate';
import NewsUpdate from './screens/News/NewsUpdate';
import NewViewPages from './screens/News/NewsView';
import { reactLocalStorage } from 'reactjs-localstorage';
import BookingAssign from "./screens/Booking/BookingAssign";
import VerifyOtp from "./screens/Auth/VerifyOtp";
import ChangePassword from "./screens/Auth/ChangePassword";
import NewsCategoryList from "./screens/NewsCategory/NewsCategoryList";
import NewsCategoryCreate from "./screens/NewsCategory/CreateNewsCategory";
import NewsCategoryView from "./screens/NewsCategory/NewsCategoryView";
import NewsCategoryUpdate from "./screens/NewsCategory/UpdateNewsCategory";
window.__DEV__ = true;

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: true,
			userData: {}
		};
	}

	componentDidMount() {
		const userData = reactLocalStorage.getObject('userData', null);
		if (!userData) {
			this.setState({ userData });
		}
	}

	render() {

		var res = window.location.pathname;
		var baseUrl = process.env.PUBLIC_URL;
		baseUrl = baseUrl.split("/");
		res = res.split("/");
		res = res.length > 0 ? res[baseUrl.length] : "/";
		res = res ? res : "/";
		const activeKey1 = res;
		console.log("***** active key *** ", activeKey1);
		return (
			<div id="wrapper">
				{activeKey1 === "" ||
					activeKey1 === "/" ||
					activeKey1 === "login" ||
					activeKey1 === "registration" ||
					activeKey1 === "lockscreen" ||
					activeKey1 === "forgotpassword" ||
					activeKey1 === "verify-otp" ||
					activeKey1 === "change-password" ||
					activeKey1 === "page404" ||
					activeKey1 === "page403" ||
					activeKey1 === "page500" ||
					activeKey1 === "page503" ||
					activeKey1 === "maintanance" ? (
					<Switch>
						{/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/`}
							component={Login}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/login`}
							component={Login}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/forgotpassword`}
							component={forgotpassword}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/verify-otp`}
							component={VerifyOtp}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/change-password`}
							component={ChangePassword}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/page404`}
							component={page404}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/page403`}
							component={page403}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/page500`}
							component={page500}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/page503`}
							component={page503}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/registration`}
							component={registration}
						/>
						<Route exact path={`registration`} component={registration} />
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/lockscreen`}
							component={lockscreen}
						/>
						<Route
							exact
							path={`${process.env.PUBLIC_URL}/maintanance`}
							component={maintanance}
						/>
					</Switch>
				) : (
					<>
						<NavbarMenu history={this.props.history} activeKey={activeKey1} />
						<div id="main-content">
							<Switch>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/dashboard`}
									component={dashboard}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/app-users`}
									component={AppUserList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/app-users/create`}
									component={CreateAppUser}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/app-users/view/:id`}
									component={AppUserView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/app-users/assign-collectors/:id`}
									component={AssignCollectors}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/app-users/update/:id`}
									component={AppUserUpdate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/setting/create`}
									component={SettingCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/team-members`}
									component={TeamMemberList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/team-members/create`}
									component={TeamMemberCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/team-members/view/:id`}
									component={TeamMemberView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/team-members/update/:id`}
									component={TeamMemberUpdate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/coupons`}
									component={CouponList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/coupons/create`}
									component={CouponCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/coupons/view/:id`}
									component={CouponView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/coupons/update/:id`}
									component={CouponUpdate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/vehicle`}
									component={VehicleList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/vehicle/create`}
									component={VehicleCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/vehicle/view/:id`}
									component={VehicleView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/vehicle/update/:id`}
									component={VehicleUpdate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-types`}
									component={WasteTypeList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-types/create`}
									component={CreateWasteType}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-types/view/:id`}
									component={WasteTypeView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-types/update/:id`}
									component={UpdateWasteType}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-type-category`}
									component={WasteTypeCategoryList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-type-category/create`}
									component={CreateWasteTypeCategory}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-type-category/view/:id`}
									component={WasteTypeCategoryView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/waste-type-category/update/:id`}
									component={UpdateWasteTypeCategory}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/booking`}
									component={BookingList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/booking/create`}
									component={BookingCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/booking/view/:id`}
									component={BookingView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/booking/assign/:id`}
									component={BookingAssign}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/invoices`}
									component={InvoiceList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/invoice/create`}
									component={InvoiceCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/invoice/view/:id`}
									component={InvoiceView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/payouts`}
									component={PayoutList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/payout/view/:id`}
									component={PayoutView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/cms-pages`}
									component={CmsPagesList}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/cms-pages/create`}
									component={CmsCreatePages}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/cms-pages/update/:id`}
									component={CmsUpdatePages}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/cms-pages/view/:id`}
									component={CmsViewPages}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/faq`}
									component={FaqList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/faq/update/:id`}
									component={FaqUpdate}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/faq/create`}
									component={FaqCreate}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news/update/:id`}
									component={NewsUpdate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news/view/:id`}
									component={NewViewPages}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news`}
									component={NewsList}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/requested-quotes`}
									component={RequestedQuotesList}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/requested-quote/view/:id`}
									component={RequestedQuotesView}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/abandon-orders`}
									component={AbandonOrdersList}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/abandon-orders/view/:id`}
									component={AbandonOrdersView}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/News/create`}
									component={NewsCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/categories`}
									component={CategoryList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/categories/create`}
									component={CategoryCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/categories/view/:id`}
									component={CategoryView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/categories/update/:id`}
									component={CategoryUpdate}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news-categories`}
									component={NewsCategoryList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news-categories/create`}
									component={NewsCategoryCreate}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news-categories/view/:id`}
									component={NewsCategoryView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/news-categories/update/:id`}
									component={NewsCategoryUpdate}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/category-item/:categoryId`}
									component={CategoryItemList}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/category-item/create/:categoryId`}
									component={CreateCategoryItem}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/category-item/view/:id`}
									component={CategoryItemView}
								/>
								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/category-item/update/:id`}
									component={UpdateCategoryItem}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/sub-category-item/update/:id`}
									component={SubCategoryItemUpdate}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/sub-category-item/view/:id`}
									component={SubCategoryItemView}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/sub-category-item/:categoryId/:categoryItemId`}
									component={SubCategoryItemList}
								/>

								<PrivateRoute
									exact
									path={`${process.env.PUBLIC_URL}/sub-category-item/create/:categoryId/:categoryItemId`}
									component={SubCategoryItemCreate}
								/>




								<Route
									exact
									path={`${process.env.PUBLIC_URL}/demographic`}
									component={demographic}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/ioT`}
									component={ioT}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/appinbox`}
									component={appInbox}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/appchat`}
									component={appChat}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/appcalendar`}
									component={appCalendar}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/appcontact`}
									component={appContact}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/apptaskbar`}
									component={appTaskbar}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/filemanagerdashboard`}
									component={filemanagerdashboard}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/filedocuments`}
									component={filedocuments}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/filemedia`}
									component={filemedia}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/fileimages`}
									component={fileimages}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/blognewpost`}
									component={blognewPost}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/blogdetails`}
									component={blogdetails}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/bloglist`}
									component={bloglist}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uitypography`}
									component={uitypography}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uitabs`}
									component={uitabs}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/bootstrapui`}
									component={bootstrapui}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uiicons`}
									component={uiicons}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uinotifications`}
									component={uinotifications}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uicolors`}
									component={uicolors}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uilistgroup`}
									component={uilistgroup}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uimediaobject`}
									component={uimediaobject}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uimodal`}
									component={uimodal}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uibuttons`}
									component={uibuttons}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/uiprogressbar`}
									component={uiprogressbar}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/widgetsdata`}
									component={widgetsdata}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/widgetsweather`}
									component={widgetsweather}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/widgetsblog`}
									component={widgetsblog}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/widgetsecommers`}
									component={widgetsecommers}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/blankpage`}
									component={blankpage}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/profilev1page`}
									component={profilev1page}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/profilev2page`}
									component={profilev2page}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/imagegalleryprofile`}
									component={imagegalleryprofile}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/timeline`}
									component={timeline}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/pricing`}
									component={pricing}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/invoices`}
									component={invoices}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/invoicesv2`}
									component={invoicesv2}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/searchresult`}
									component={searchresult}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/helperclass`}
									component={helperclass}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/teamsboard`}
									component={teamsboard}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/projectslist`}
									component={projectslist}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/testimonials`}
									component={testimonials}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/faqs`}
									component={faqs}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/formvalidation`}
									component={formvalidation}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/basicelements`}
									component={basicelements}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/tablenormal`}
									component={tablenormal}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/echart`}
									component={echart}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/leafletmap`}
									component={leafletmap}
								/>
							</Switch>
						</div>
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ loginReducer }) => ({
	isLoggedin: loginReducer.isLoggedin,
});

export default withRouter(connect(mapStateToProps, {})(App));

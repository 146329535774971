import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { updateUserAction, viewUserAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Loader from "react-loader-spinner";
import constants from '../../config/constants';

const schema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required('Last Name is required'),
    password: yup.string().notRequired(),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const options = [
    {
        value: constants.ROLE.USER,
        label: "Customer"
    },
    {
        value: constants.ROLE.COLLECTOR,
        label: "Waste Collector"
    }
];

function AppUserUpdate({ updateUserAction, viewUserAction }) {
    const history = useHistory();
    const [user, setAppUserData] = useState(null);
    const params = useParams();
    const id = params.id;
    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            values.role = 'WasteCollector';
            updateUserAction(id, values, (err, response) => {
                if (!err) {
                    history.push('/team-members');
                } else {
                    formik.setSubmitting(false);
                }
            })
        },
    });

    useEffect(() => {

        viewUserAction({ id: id }, (err, response) => {
            if (!err) {
                setAppUserData(response.data.data);
                let userData = response.data.data;
                formik.setValues({
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    password: '',
                    confirmPassword: ''
                });
            }
        });
    }, [id]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {user ?
                    <>
                        <div className="header">
                            <h2>
                                Update User
                            </h2>
                        </div>
                        <div className="body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                className={`form-control ${formik.errors.email && formik.touched.email && "parsley-error"
                                                    }`}
                                                value={formik.values.email}
                                                name="email"
                                                id="email"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.email && formik.touched.email &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.email}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input
                                                className={`form-control ${formik.errors.firstName && formik.touched.firstName && "parsley-error"
                                                    }`}
                                                value={formik.values.firstName}
                                                name="firstName"
                                                id="firstName"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.firstName && formik.touched.firstName &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.firstName}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input
                                                className={`form-control ${formik.errors.lastName && formik.touched.lastName && "parsley-error"
                                                    }`}
                                                value={formik.values.lastName}
                                                name="lastName"
                                                id="lastName"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.lastName && formik.touched.lastName &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.lastName}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input
                                                className={`form-control ${formik.errors.password && formik.touched.password && "parsley-error"
                                                    }`}
                                                value={formik.values.password}
                                                name="password"
                                                id="password"
                                                type="password"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.password && formik.touched.password &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.password}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input
                                                className={`form-control ${formik.errors.confirmPassword && formik.touched.confirmPassword && "parsley-error"
                                                    }`}
                                                type="password"
                                                value={formik.values.confirmPassword}
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.confirmPassword && formik.touched.confirmPassword &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.confirmPassword}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                                </div>
                            </form>
                        </div>
                    </> :
                    <div className="text-center">
                        <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default connect(null, { updateUserAction, viewUserAction })(AppUserUpdate);
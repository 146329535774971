import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { viewVehicleAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function VehicleView({ viewVehicleAction }) {
    const [vehicleData, setVehicleData] = useState(null);
    const history = useHistory();
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        viewVehicleAction({ id: id }, (err, response) => {
            if (!err) {
                setVehicleData(response.data.data);
            }
        });
    }, [id, viewVehicleAction]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    vehicleData ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {vehicleData.title}
                                </h2>
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Make:</th>
                                            <td>{vehicleData.make}</td>

                                        </tr>
                                        <tr>
                                            <th>Model:</th>
                                            <td>{vehicleData.model}</td>

                                        </tr>
                                        <tr>
                                            <th>Registration No.:</th>
                                            <td>{vehicleData.registrationNumber}</td>

                                        </tr>
                                        <tr>
                                            <th>Capacity:</th>
                                            <td>{vehicleData.capacity}</td>

                                        </tr>
                                        <tr>
                                            <th>Number Plate:</th>
                                            <td>{vehicleData.numberPlate}</td>
                                        </tr>
                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(vehicleData.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </> :
                        <div className="text-center">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>

                }

            </div>
        </div>
    )
}

export default connect(null, { viewVehicleAction })(VehicleView)
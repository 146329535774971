import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import WasteTypeViewComponent from "../../components/WasteType/WasteTypeView";
import { useParams } from "react-router-dom";
import { viewWasteTypeAction } from "../../actions";
import { connect } from 'react-redux';

 function WasteTypeView( { viewWasteTypeAction } ) {
    const params = useParams();
    const id = params.id;
    const [wasteType, setWasteTypeData] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewWasteTypeAction({ id: id }, (err, response) => {
                if (!err) {
                    setWasteTypeData(response.data.data);
                }
            });
        }
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {wasteType && <PageHeader
                        HeaderText="Special Waste Type Detail"
                        Breadcrumb={[
                            { name: "Special Waste Types", navigate: "/waste-types" },
                            { name: wasteType.title, navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <WasteTypeViewComponent wasteType={wasteType}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewWasteTypeAction })(WasteTypeView)

import axios from '../config/axios';
import { BASE_URL } from '../config/constants';
import { handleError } from './LoginAction';
import { SHOW_NOTIFICATION } from './UIElementsAction';

export const listCategoryItemAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/category-item/list', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const listCategoryItemForBookingAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/category-item/list-for-booking', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const viewCategoryItemAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/category-item/view', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const createCategoryItemAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/category-item/create', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const updateCategoryItemAction = (id, params, cb) => (dispatch) => {
    axios.put(BASE_URL + `/admin/category-item/update?id=${id}`, params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const deleteCategoryItemAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/category-item/delete', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}
import axios from '../config/axios';
import { BASE_URL } from '../config/constants';
import { handleError } from './LoginAction';
import { SHOW_NOTIFICATION } from './UIElementsAction';

export const listBookingAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/booking/list', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const viewBookingAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/booking/view', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const viewSettingAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/setting/view', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const createBookingAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/booking/create', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const markBookingPaidAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/booking/mark-paid', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const claimBookingAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/manager/booking/claim', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const assignBookingAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/manager/booking/assign', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: 'Booking Assigned Successfully',
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const updateBookingAction = (id, params, cb) => (dispatch) => {
    axios.put(BASE_URL + `/admin/booking/update?id=${id}`, params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const deleteBookingAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/booking/delete', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const getAddressByPostcode = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/booking/getPostcodeDetails', {
        postCode: params,
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const getVatVal = (cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/booking/vat-value').then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const applyCoupon = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/booking/apply-coupon', params).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}
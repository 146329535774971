import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createAppUserAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';


const schema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required('Last Name is required'),
    password: yup.string().required('Password is required'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});


function AppUserCreate({ createAppUserAction }) {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            values.role = 'WasteCollector';
            createAppUserAction(values, (err, response) => {
                if (!err) {
                    history.push('/team-members');
                } else {
                    formik.setSubmitting(false);
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create User
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        className={`form-control ${formik.errors.email && formik.touched.email && "parsley-error"
                                            }`}
                                        value={formik.values.email}
                                        name="email"
                                        id="email"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.email && formik.touched.email &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.email}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        className={`form-control ${formik.errors.firstName && formik.touched.firstName && "parsley-error"
                                            }`}
                                        value={formik.values.firstName}
                                        name="firstName"
                                        id="firstName"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.firstName && formik.touched.firstName &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.firstName}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        className={`form-control ${formik.errors.lastName && formik.touched.lastName && "parsley-error"
                                            }`}
                                        value={formik.values.lastName}
                                        name="lastName"
                                        id="lastName"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.lastName && formik.touched.lastName &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.lastName}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        className={`form-control ${formik.errors.password && formik.touched.password && "parsley-error"
                                            }`}
                                        value={formik.values.password}
                                        name="password"
                                        id="password"
                                        type="password"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.password && formik.touched.password &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.password}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        className={`form-control ${formik.errors.confirmPassword && formik.touched.confirmPassword && "parsley-error"
                                            }`}
                                        type="password"
                                        value={formik.values.confirmPassword}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.confirmPassword && formik.touched.confirmPassword &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.confirmPassword}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createAppUserAction })(AppUserCreate);
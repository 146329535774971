import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { reactLocalStorage } from "reactjs-localstorage";

export default function PrivateRoute({ component, ...rest }) {
    let token = reactLocalStorage.get('access_token', null);
    let Cp = component;
    return (
        <Route {...rest} render={() => {
            return token
                ? <Cp />
                : <Redirect to='/admin/login' />
        }} />
    )
}
import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/logo-white.svg";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { forgotPassword, notifyOnClose } from "../../actions";
import { reactLocalStorage } from 'reactjs-localstorage';
import { Toast } from "react-bootstrap";
const schema = yup.object().shape({
  email: yup.string().required('email is required')
})

function ForgotPassword({ forgotPassword, notifyData, notifyOnClose }) {

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      forgotPassword({ username: values.email }, (err, response) => {
        if (!err) {
          reactLocalStorage.set('session', response.data.data.session);
          history.push('/verify-otp');
        }
      })
    },
  });


  return (
    <div className="theme-cyan">
      <Toast
        id="toast-container"
        show={true}
        className={`toast-top-right toast-container`}
        style={{ background: "transparent" }}
        onClose={() => { }}
      >
        {notifyData.map((data, i) => {
          if (data.position === "top-right") {
            setTimeout(() => {
              notifyOnClose(i);
            }, 10000);
            return (
              <Toast.Body
                key={"keys" + i}
                className={`toast-${data.type} mb-10 ml-0`}
                style={{ opacity: 1 }}
              >
                <strong className="mr-auto"> {data.dialogText} </strong>
                <button
                  className="toast-close-button"
                  onClick={() => {
                    notifyOnClose(i);
                  }}
                >
                  x
                </button>
              </Toast.Body>
            );
          }
        })}
      </Toast>
      <div >
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img src={Logo} alt="Trash2Go" style={{ height: "40px", margin: "10px" }} />
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">Recover my password</p>
                </div>
                <div className="body">
                  <p>Please enter your email address below to receive instructions for resetting password.</p>
                  <form onSubmit={formik.handleSubmit} className="form-auth-small ng-untouched ng-pristine ng-valid">
                    <div className="form-group">
                      <input className="form-control" placeholder="Email" id="email" onChange={formik.handleChange} name="email" value={formik.values.email} type="text" />
                    </div>
                    <button className="btn btn-primary btn-lg btn-block" type="submit">
                      RESET PASSWORD
                    </button>
                    <div className="bottom">
                      <span className="helper-text">Know your password? <a href="login">Login</a></span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = ({ loginReducer, UIElementsReducer }) => {
  const { notifyData } = UIElementsReducer;
  return { notifyData };
};

export default connect(mapStateToProps, {
  forgotPassword,
  notifyOnClose
})(ForgotPassword);

import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import BookingAssignComponent from "../../components/Booking/BookingAssign";
import { useParams } from "react-router-dom";
import { viewBookingAction } from "../../actions";
import { connect } from 'react-redux';


function BookingAssign({ viewBookingAction }) {
    const params = useParams();
    const id = params.id;
    const [booking, setBookingData] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewBookingAction({ id: id }, (err, response) => {
                if (!err) {
                    setBookingData(response.data.data);
                }
            });
        }
    }, [id]);


    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {booking && <PageHeader
                        HeaderText="Assign Booking"
                        Breadcrumb={[
                            { name: "Bookings", navigate: "/booking" },
                            { name: "Booking Detail", navigate: `/booking/${booking.id}` },
                            { name: "Assign Booking", navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <BookingAssignComponent booking={booking} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewBookingAction })(BookingAssign)

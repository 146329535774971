import axios from '../config/axios';
import { BASE_URL } from '../config/constants';
import { handleError } from './LoginAction';
import { SHOW_NOTIFICATION } from './UIElementsAction';
export const listCategoryAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/category/list', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const listCategoryForBookingAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/category/list-for-booking', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const viewCategoryAction = (params, cb) => (dispatch) => {
    axios.get(BASE_URL + '/admin/category/view', {
        params: params
    }).then((response) => {
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const createCategoryAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/category/create', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}


export const updateCategoryAction = (id, params, cb) => (dispatch) => {
    axios.put(BASE_URL + `/admin/category/update?id=${id}`, params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}

export const deleteCategoryAction = (params, cb) => (dispatch) => {
    axios.post(BASE_URL + '/admin/category/delete', params).then((response) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                type: "success",
                position: "top-right",
                dialogText: response.data.message,
                show: true,
            },
        });
        if (cb) {
            return cb(null, response);
        }
    }).catch(err => {
        handleError(dispatch, err);
        if (cb) {
            return cb(err);
        }
    })
}
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createCouponAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';

const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    code: yup.string().required('Code is required'),
    type: yup.number().required("Type is required"),
    flatAmount: yup.number().when("type", {
        is: 0,
        then: yup.number().required("Amount is required"),
        otherwise: yup.number().notRequired()
    }),
    percentage: yup.number().when("type", {
        is: 1,
        then: yup.number().required("Percentage is required"),
        otherwise: yup.number().notRequired()
    }),
    maximumDiscount: yup.number().when("type", {
        is: 1,
        then: yup.number().required("Maximum disount is required"),
        otherwise: yup.number().notRequired()
    }),
    validUpto: yup.string().required('Valid Upto is required')
});


function CouponCreate({ createCouponAction }) {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            code: '',
            type: 0,
            flatAmount: 0,
            percentage: 0,
            maximumDiscount: 0,
            validUpto: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            createCouponAction(values, (err, response) => {
                if (!err) {
                    history.push('/coupons');
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Coupon
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                className={`form-control ${formik.errors.title && formik.touched.title && "parsley-error"
                                    }`}
                                value={formik.values.title}
                                name="title"
                                id="title"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.title && formik.touched.title &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.title}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <input
                                className={`form-control ${formik.errors.description && formik.touched.description && "parsley-error"
                                    }`}
                                value={formik.values.description}
                                name="description"
                                id="description"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.description && formik.touched.description &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.description}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Code</label>
                            <input
                                className={`form-control ${formik.errors.code && formik.touched.code && "parsley-error"
                                    }`}
                                value={formik.values.code}
                                name="code"
                                id="code"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.code && formik.touched.code &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.code}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <label>Type</label>
                            <Select
                                defaultValue={formik.values.type}
                                onChange={(value) => {
                                    formik.setFieldValue('type', value.value);
                                }}
                                name="colors"
                                options={[
                                    {
                                        value: 0,
                                        label: "Flat"
                                    },
                                    {
                                        value: 1,
                                        label: "Percetage"
                                    }
                                ]}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                            {
                                formik.errors.type && formik.touched.type &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.type}
                                    </li>
                                </ul>
                            }
                        </div>
                        {(formik.values.type === 0) && <div className="form-group">
                            <label>Flat Amount</label>
                            <input
                                type="number"
                                className={`form-control ${formik.errors.flatAmount && formik.touched.flatAmount && "parsley-error"
                                    }`}
                                value={formik.values.flatAmount}
                                name="flatAmount"
                                id="flatAmount"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.flatAmount && formik.touched.flatAmount &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.description}
                                    </li>
                                </ul>
                            }
                        </div>}
                        {(formik.values.type === 1) && <div className="form-group">
                            <label>Percentage</label>
                            <input
                                type="number"
                                className={`form-control ${formik.errors.percentage && formik.touched.percentage && "parsley-error"
                                    }`}
                                value={formik.values.percentage}
                                name="percentage"
                                id="percentage"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.percentage && formik.touched.percentage &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.percentage}
                                    </li>
                                </ul>
                            }
                        </div>}
                        {(formik.values.type === 1) && <div className="form-group">
                            <label>Maximum Discount</label>
                            <input
                                type="number"
                                className={`form-control ${formik.errors.maximumDiscount && formik.touched.maximumDiscount && "parsley-error"
                                    }`}
                                value={formik.values.maximumDiscount}
                                name="maximumDiscount"
                                id="maximumDiscount"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.maximumDiscount && formik.touched.maximumDiscount &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.maximumDiscount}
                                    </li>
                                </ul>
                            }
                        </div>}
                        <div className="form-group">
                            <label>Valid Upto</label>
                            <Datetime onChange={(value) => {
                                formik.setFieldValue('validUpto', value.toISOString());
                            }} />
                            {
                                formik.errors.validUpto && formik.touched.validUpto &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.validUpto}
                                    </li>
                                </ul>
                            }
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createCouponAction })(CouponCreate);
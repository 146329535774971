import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { viewCategoryAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function CategoryView({ categoryItem }) {
    const history = useHistory();
    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    categoryItem ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {categoryItem.title}
                                </h2>
                                <button onClick={() => { history.push(`/sub-category-item/${categoryItem.categoryId._id}/${categoryItem._id}`) }} className="btn btn-outline-secondary" >Sub Category Items</button>
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Title:</th>
                                            <td>{categoryItem.title}</td>

                                        </tr>
                                        <tr>
                                            <th>Image:</th>
                                            <td><img width="60" src={categoryItem.imagePath} className="rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th>Price:</th>
                                            <td>{categoryItem.price}</td>
                                        </tr>
                                        <tr>
                                            <th>Category:</th>
                                            <td>{categoryItem.categoryId.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(categoryItem.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </> :
                        <div className="text-center">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>

                }

            </div>
        </div>
    )
}

export default connect(null, { viewCategoryAction })(CategoryView)
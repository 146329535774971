import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Dropzone from 'react-dropzone';
import { createAppUserAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Switch from "react-switch";
import Select from 'react-select';
import constants from '../../config/constants';

const schema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required('Last Name is required'),
    password: yup.string().required('Password is required'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    isBusiness: yup.boolean().required(),
    corporateDiscount: yup.number().optional(),
    role: yup.string().required('Role is required'),
    status: yup.string().required('Status is required'),
    introducerCode: yup.string().when('role', { is: constants.ROLE.INTRODUCER, then: yup.string().required('Introducer Code is required'), otherwise: yup.string().notRequired() }),
    companyName: yup.string().when('role', { is: constants.ROLE.COLLECTOR, then: yup.string().required('Company Name is required'), otherwise: yup.string().notRequired() }),
    businessName: yup.string().when('isBusiness', { is: true, then: yup.string().required('Business name is required'), otherwise: yup.string().notRequired() }).when('role', { is: constants.ROLE.CORPORATE, then: yup.string().required('Business name is required'), otherwise: yup.string().notRequired() }),
    companyRegistrationNumber: yup.string().when('isBusiness', { is: true, then: yup.string().required('Company registration number is required'), otherwise: yup.string().notRequired() }),
    address: yup.string().when('isBusiness', { is: true, then: yup.string().required('Address is required'), otherwise: yup.string().notRequired() }).when('role', { is: constants.ROLE.CORPORATE, then: yup.string().required('Address is required'), otherwise: yup.string().notRequired() }),
    town: yup.string().when('isBusiness', { is: true, then: yup.string().required('Town is required'), otherwise: yup.string().notRequired() }),
    postalCode: yup.string().when('isBusiness', { is: true, then: yup.string().required('Postal code is required'), otherwise: yup.string().notRequired() }),
    wasteCollectorPercentage: yup.number().when('role', { is: constants.ROLE.COLLECTOR, then: yup.number().required(), otherwise: yup.number().default(70).notRequired() })
});


const statuses = [
    {
        value: 0,
        label: "Inactive"
    },
    {
        value: 1,
        label: "Active"
    }
];

function AppUserCreate({ createAppUserAction }) {
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            isBusiness: false,
            role: '',
            status: 0,
            companyName: '',
            businessName: '',
            companyRegistrationNumber: '',
            address: '',
            town: '',
            postalCode: '',
            wasteCollectorPercentage: 70,
            corporateDiscount: 0,
            introducerCode: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            createAppUserAction(values, (err, response) => {
                if (!err) {
                    history.push('/app-users');
                }
            })
        },
    });

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create User
                    </h2>
                </div>
                <div className="body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        className={`form-control ${formik.errors.email && formik.touched.email && "parsley-error"
                                            }`}
                                        value={formik.values.email}
                                        name="email"
                                        id="email"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.email && formik.touched.email &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.email}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Role</label>
                                    <Select
                                        defaultValue={formik.values.role}
                                        onChange={(value) => {
                                            formik.setFieldValue('role', value.value);
                                        }}
                                        name="colors"
                                        options={[
                                            {
                                                value: constants.ROLE.USER,
                                                label: "Customer"
                                            },
                                            {
                                                value: constants.ROLE.COLLECTOR,
                                                label: "Waste Collector"
                                            },
                                            {
                                                value: constants.ROLE.CORPORATE,
                                                label: "Corporate"
                                            },
                                            {
                                                value: constants.ROLE.INTRODUCER,
                                                label: "Introducer"
                                            }
                                        ]}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                    {
                                        formik.errors.role && formik.touched.role &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.role}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        className={`form-control ${formik.errors.firstName && formik.touched.firstName && "parsley-error"
                                            }`}
                                        value={formik.values.firstName}
                                        name="firstName"
                                        id="firstName"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.firstName && formik.touched.firstName &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.firstName}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        className={`form-control ${formik.errors.lastName && formik.touched.lastName && "parsley-error"
                                            }`}
                                        value={formik.values.lastName}
                                        name="lastName"
                                        id="lastName"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.lastName && formik.touched.lastName &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.lastName}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        className={`form-control ${formik.errors.password && formik.touched.password && "parsley-error"
                                            }`}
                                        value={formik.values.password}
                                        name="password"
                                        id="password"
                                        type="password"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.password && formik.touched.password &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.password}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input
                                        className={`form-control ${formik.errors.confirmPassword && formik.touched.confirmPassword && "parsley-error"
                                            }`}
                                        type="password"
                                        value={formik.values.confirmPassword}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.confirmPassword && formik.touched.confirmPassword &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.confirmPassword}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>

                        </div>
                        {(formik.values.role === constants.ROLE.USER) && <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="d-flex align-items-center">
                                        <label className="pr-3 mb-0">
                                            <span>Is Business ?</span>
                                        </label>
                                        <Switch onChange={(checked) => {
                                            formik.setFieldValue('isBusiness', checked);
                                        }} checked={formik.values.isBusiness} />
                                    </div>
                                    {
                                        formik.errors.confirmPassword && formik.touched.confirmPassword &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.confirmPassword}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>

                            {((formik.values.role === constants.ROLE.USER) && formik.values.isBusiness) && <div className="col-md-6">
                                <div className="form-group">
                                    <label>Business Name</label>
                                    <input
                                        className={`form-control ${formik.errors.businessName && formik.touched.businessName && "parsley-error"
                                            }`}
                                        value={formik.values.businessName}
                                        name="businessName"
                                        id="businessName"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.businessName && formik.touched.businessName &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.businessName}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>}
                        </div>}
                        {((formik.values.role === constants.ROLE.INTRODUCER)) && <div className="row"><div className="col-md-6">
                            <div className="form-group">
                                <label>Introducer Code</label>
                                <input
                                    className={`form-control ${formik.errors.introducerCode && formik.touched.introducerCode && "parsley-error"
                                        }`}
                                    value={formik.values.introducerCode}
                                    name="introducerCode"
                                    id="introducerCode"
                                    onChange={formik.handleChange}
                                />
                                {
                                    formik.errors.introducerCode && formik.touched.introducerCode &&
                                    <ul className="parsley-errors-list filled" id="parsley-id-29">
                                        <li className="parsley-required">
                                            {formik.errors.introducerCode}
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div></div>}
                        {(formik.values.role === constants.ROLE.COLLECTOR) && <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Company Name</label>
                                    <input
                                        className={`form-control ${formik.errors.companyName && formik.touched.companyName && "parsley-error"
                                            }`}
                                        type="text"
                                        value={formik.values.companyName}
                                        name="companyName"
                                        id="companyName"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.companyName && formik.touched.companyName &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.companyName}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Collector Percentage</label>
                                    <input
                                        className={`form-control ${formik.errors.wasteCollectorPercentage && formik.touched.wasteCollectorPercentage && "parsley-error"
                                            }`}
                                        type="text"
                                        value={formik.values.wasteCollectorPercentage}
                                        name="wasteCollectorPercentage"
                                        id="wasteCollectorPercentage"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.wasteCollectorPercentage && formik.touched.wasteCollectorPercentage &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.wasteCollectorPercentage}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>}
                        {((formik.values.role === constants.ROLE.USER) && formik.values.isBusiness) && <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Company Registration Number</label>
                                    <input
                                        className={`form-control ${formik.errors.companyRegistrationNumber && formik.touched.companyRegistrationNumber && "parsley-error"
                                            }`}
                                        type="text"
                                        value={formik.values.companyRegistrationNumber}
                                        name="companyRegistrationNumber"
                                        id="companyRegistrationNumber"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.companyRegistrationNumber && formik.touched.companyRegistrationNumber &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.companyRegistrationNumber}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input
                                        className={`form-control ${formik.errors.address && formik.touched.address && "parsley-error"
                                            }`}
                                        type="text"
                                        value={formik.values.address}
                                        name="address"
                                        id="address"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.address && formik.touched.address &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.address}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        {((formik.values.role === constants.ROLE.USER) && formik.values.isBusiness) && <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Town</label>
                                    <input
                                        className={`form-control ${formik.errors.town && formik.touched.town && "parsley-error"
                                            }`}
                                        type="text"
                                        value={formik.values.town}
                                        name="town"
                                        id="town"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.town && formik.touched.town &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.town}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Postal Code</label>
                                    <input
                                        className={`form-control ${formik.errors.postalCode && formik.touched.postalCode && "parsley-error"
                                            }`}
                                        type="text"
                                        value={formik.values.postalCode}
                                        name="postalCode"
                                        id="postalCode"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.postalCode && formik.touched.postalCode &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.postalCode}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>}

                        {formik.values.role === constants.ROLE.CORPORATE &&
                            <>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Name</label>
                                            <input
                                                className={`form-control ${formik.errors.businessName && formik.touched.businessName && "parsley-error"
                                                    }`}
                                                value={formik.values.businessName}
                                                name="businessName"
                                                id="businessName"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.businessName && formik.touched.businessName &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.businessName}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input
                                                className={`form-control ${formik.errors.address && formik.touched.address && "parsley-error"
                                                    }`}
                                                type="text"
                                                value={formik.values.address}
                                                name="address"
                                                id="address"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.address && formik.touched.address &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.address}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Corporate Discount</label>
                                            <input
                                                className={`form-control ${formik.errors.corporateDiscount && formik.touched.corporateDiscount && "parsley-error"
                                                    }`}
                                                type="number"
                                                value={formik.values.corporateDiscount}
                                                name="corporateDiscount"
                                                id="corporateDiscount"
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                formik.errors.corporateDiscount && formik.touched.corporateDiscount &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                                    <li className="parsley-required">
                                                        {formik.errors.corporateDiscount}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status</label>
                                    <Select
                                        defaultValue={formik.values.status}
                                        onChange={(value) => {
                                            formik.setFieldValue('status', value.value);
                                        }}
                                        name="colors"
                                        options={statuses}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                    {
                                        formik.errors.status && formik.touched.status &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.status}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createAppUserAction })(AppUserCreate);

import React from "react";
import PageHeader from "../../components/PageHeader";
import FaqUpdateComponent from "../../components/FAQ/FaqUpdate";

class FaqUpdate extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div
                style={{ flex: 1 }}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="FAQ Update"
                            Breadcrumb={[
                                { name: "FAQ", navigate: "/faq" },
                                { name: "FAQ Update", navigate: "" },
                            ]}
                        />
                        <div className="row clearfix">
                            <FaqUpdateComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqUpdate;

import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { viewBookingAction, markBookingPaidAction, claimBookingAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Accordion, useAccordionToggle } from 'react-bootstrap'
import Viewer from 'react-viewer';
import { useHistory } from 'react-router-dom';
import { reactLocalStorage } from "reactjs-localstorage";

function getStatus(row) {
    if ((row.status === 0) && (row.paymentStatus === 0)) {
        return <span className="badge badge-info">Abandon</span>
    } else if ((row.paymentStatus === 2) && (row.bookingStatus === 1)) {
        return <span className="badge badge-info">Awaiting Payment</span>
    } else if (row.bookingStatus === 1) {
        return <span className="badge badge-success">Placed</span>
    } else if (row.bookingStatus === 2) {
        return <span className="badge badge-warning">In Progress</span>
    } else if (row.bookingStatus === 3) {
        return <span className="badge badge-success">Completed</span>
    } else if (row.bookingStatus === 4) {
        return <span className="badge badge-success">Claimed</span>
    } else if (row.bookingStatus === 5) {
        return <span className="badge badge-danger">Photos Uploaded</span>
    } else if (row.bookingStatus === 6) {
        return <span className="badge badge-success">Customer Signed</span>
    } else if (row.bookingStatus === 7) {
        return <span className="badge badge-success">Collector Signed</span>
    } else if (row.bookingStatus === 8) {
        return <span className="badge badge-success">Final Photos Uploaded</span>
    } else if (row.bookingStatus === 9) {
        return <span className="badge badge-success">Tipping Location Selected</span>
    } else if (row.bookingStatus === 10) {
        return <span className="badge badge-success">Completed</span>
    } else if (row.bookingStatus === 11) {
        return <span className="badge badge-success">Cancelled</span>
    } else if (row.bookingStatus === 12) {
        return <span className="badge badge-success">Assigned</span>
    }
}


function getPaymentStatus(row) {
    if (row.paymentStatus === 2) {
        return <span className="badge badge-info">Pay Later</span>
    } if (row.paymentStatus === 1) {
        return <span className="badge badge-success">Paid</span>
    } if (row.paymentStatus === 0) {
        return <span className="badge badge-danger">Pending</span>
    }
}

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a
            style={{ color: "#007bff", cursor: "pointer" }}
            onClick={decoratedOnClick}
        >
            {children}
        </a>
    );
}



function ImageViewer({ images }) {
    const [visible, setVisible] = React.useState(false);

    return (
        <div>
            <a style={{ color: "#007bff", cursor: "pointer" }} onClick={() => { setVisible(true); }}>View</a>
            <Viewer
                visible={visible}
                onClose={() => { setVisible(false); }}
                images={images}
            />
        </div>
    );
}

function BookingView({ booking, markBookingPaidAction, claimBookingAction, refreshBooking }) {
    const history = useHistory();
    const [user, setUser] = useState(reactLocalStorage.getObject('user', null));
    const markPaid = (bookingId) => {
        markBookingPaidAction({ id: bookingId }, (err, response) => {
            if (!err) {
                history.push(`/booking`)
            }
        })
    }

    const claimBooking = (bookingId) => {
        claimBookingAction({ id: bookingId }, (err, response) => {
            if (!err) {
                refreshBooking();
            }
        })
    }



    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    booking ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {booking.bookingToken}
                                </h2>
                                {((booking.paymentStatus === 2) || (booking.paymentStatus === 0)) && ((user.role === 'SuperAdmin')) && <a href='#' onClick={(e) => { e.preventDefault(); markPaid(booking._id) }} className='btn btn-outline-secondary'>Mark Paid</a>}
                                {((booking.bookingStatus === 1) || (booking.bookingStatus === 1)) && ((user.role === 'BusinessManager')) && <a href='#' onClick={(e) => { e.preventDefault(); claimBooking(booking._id) }} className='btn btn-outline-secondary'>Claim Booking</a>}
                                {((booking.bookingStatus === 4) || (booking.bookingStatus === 4)) && ((user.role === 'BusinessManager')) && <a href='#' onClick={(e) => { e.preventDefault(); history.push(`/booking/assign/${booking._id}`) }} className='btn btn-outline-secondary'>Assign Booking</a>}
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Customer First Name:</th>
                                            <td>{booking.userId.firstName}</td>
                                        </tr>
                                        <tr>
                                            <th>Customer Last Name:</th>
                                            <td>{booking.userId.lastName}</td>
                                        </tr>
                                        {
                                            booking.collectorId ?
                                                <>
                                                    <tr>
                                                        <th>Collector First Name:</th>
                                                        <td>{booking.collectorId ? booking.collectorId.firstName : 'NA'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Collector Last Name:</th>
                                                        <td>{booking.collectorId ? booking.collectorId.lastName : 'NA'}</td>
                                                    </tr>
                                                </> :
                                                null
                                        }
                                        <tr>
                                            <th>Customer Phone Number:</th>
                                            <td>{booking.userId.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Time:</th>
                                            <td>{booking.time}</td>
                                        </tr>
                                        <tr>
                                            <th>Status:</th>
                                            <td>{getStatus(booking)}</td>
                                        </tr>
                                        <tr>
                                            <th>Payment Status:</th>
                                            <td>{getPaymentStatus(booking)}</td>
                                        </tr>
                                        {(user.role === 'SuperAdmin') && <tr>
                                            <th>Total:</th>
                                            <td>{'£' + booking.totalPrice}</td>
                                        </tr>}
                                        <tr>
                                            <th>Collector Commission:</th>
                                            <td>{'£' + booking.collectorCommission}</td>
                                        </tr>
                                        <tr>
                                            <th>Tax:</th>
                                            <td>{'£' + booking.vat}</td>
                                        </tr>
                                        <tr>
                                            <th>Address:</th>
                                            <td>{booking.address}</td>
                                        </tr>
                                        <tr>
                                            <th>Postal Code:</th>
                                            <td>{booking.postCode}</td>
                                        </tr>
                                        <tr>
                                            <th>Items:</th>
                                            <td>
                                                <Accordion>
                                                    <CustomToggle eventKey="0">Click Here</CustomToggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <div style={{ marginTop: "10px" }}>
                                                            {
                                                                booking.items.map(item => {
                                                                    return <div key={item._id}>
                                                                        <p className="mb-1"><b>Item: </b>{item.title}</p>
                                                                        <p className="mb-1"><b>Price: </b>{item.price}</p>
                                                                        <p className="mb-1"><b>Quantity: </b>{item.quantity}</p>
                                                                        <hr></hr>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Accordion>
                                            </td>
                                        </tr>

                                        {booking.modificationStatus != 0 &&

                                            <>
                                                <tr>
                                                    <th>Modified Items:</th>
                                                    <td>
                                                        <Accordion>
                                                            <CustomToggle eventKey="0">Click Here</CustomToggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <div style={{ marginTop: "10px" }}>
                                                                    {
                                                                        booking.modifiedItems.map(item => {
                                                                            return <div key={item._id}>
                                                                                <p className="mb-1"><b>Item: </b>{item.title}</p>
                                                                                <p className="mb-1"><b>Price: </b>{item.price}</p>
                                                                                <p className="mb-1"><b>Quantity: </b>{item.quantity}</p>
                                                                                <hr></hr>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Modified Price:</th>
                                                    <td>{'£' + booking.differenceAmount}</td>
                                                </tr>
                                            </>
                                        }

                                        {(booking.imagePaths.length > 0) &&
                                            <tr>
                                                <th>Booking Images :</th>
                                                <td><ImageViewer images={booking.imagePaths.map(item => { return { src: item, alt: "" } })} /></td>
                                            </tr>
                                        }

                                        {(booking.preImagesPaths.length > 0) &&
                                            <tr>
                                                <th>Booking Pre Images :</th>
                                                <td><ImageViewer images={booking.preImagesPaths.map(item => { return { src: item, alt: "" } })} /></td>
                                            </tr>
                                        }

                                        {
                                            (booking.postImagesPaths.length > 0) &&
                                            <tr>
                                                <th>Booking Post Images :</th>
                                                <td><ImageViewer images={booking.postImagesPaths.map(item => { return { src: item, alt: "" } })} /></td>
                                            </tr>
                                        }
                                        {
                                            (booking.modificationImagePaths.length > 0) &&
                                            <tr>
                                                <th>Booking Modification Images :</th>
                                                <td><ImageViewer images={booking.modificationImagePaths.map(item => { return { src: item, alt: "" } })} /></td>
                                            </tr>
                                        }

                                        {booking.customerSignaturePath && <tr>
                                            <th>Customer Signature :</th>
                                            <td><ImageViewer images={[{ src: booking.customerSignaturePath, alt: "" }]} /></td>
                                        </tr>}
                                        {booking.providerSignaturePath && <tr>
                                            <th>Collector Signature :</th>
                                            <td><ImageViewer images={[{ src: booking.providerSignaturePath, alt: "" }]} /></td>
                                        </tr>}
                                        {booking.tippingImagePath && <tr>
                                            <th>Tipping Image :</th>
                                            <td><ImageViewer images={[{ src: booking.tippingImagePath, alt: "" }]} /></td>
                                        </tr>}
                                        {booking.vehicleId && <tr>
                                            <th>Vehicle:</th>
                                            <td>{booking.vehicleId.make}</td>
                                        </tr>}
                                        {booking.tippingLocationId && <tr>
                                            <th>Tipping Location:</th>
                                            <td>{booking.tippingLocationId.address}</td>
                                        </tr>}
                                        {booking.wtnNumber && <tr>
                                            <th>WTN Number:</th>
                                            <td>{booking.wtnNumber}</td>
                                        </tr>}
                                        {booking.notes && <tr>
                                            <th>Notes :</th>
                                            <td>{booking.notes}</td>
                                        </tr>}
                                        {booking.preNote && <tr>
                                            <th>Pre Notes :</th>
                                            <td>{booking.preNote}</td>
                                        </tr>}
                                        {booking.postNote && <tr>
                                            <th>Post Notes :</th>
                                            <td>{booking.postNote}</td>
                                        </tr>}
                                        <tr>
                                            <th>Journey Wasted:</th>
                                            <td>{booking.journeyWasted === 0 ? "No" : "Yes"}</td>
                                        </tr>
                                        {booking.reason && <tr>
                                            <th>Reason:</th>
                                            <td>{booking.reason}</td>
                                        </tr>}
                                        {
                                            booking.startedAt &&
                                            <tr>
                                                <th>Started At:</th>
                                                <td>{moment(booking.startedAt).format('YYYY-MM-DD hh:mm a')}</td>
                                            </tr>
                                        }
                                        {
                                            booking.claimedAt &&
                                            <tr>
                                                <th>Claimed At:</th>
                                                <td>{moment(booking.claimedAt).format('YYYY-MM-DD hh:mm a')}</td>
                                            </tr>
                                        }
                                        {
                                            booking.arrivedAt &&
                                            <tr>
                                                <th>Arrived At:</th>
                                                <td>{moment(booking.arrivedAt).format('YYYY-MM-DD hh:mm a')}</td>
                                            </tr>
                                        }
                                        {
                                            booking.datetime &&
                                            <tr>
                                                <th>Collection Date:</th>
                                                <td>{moment(booking.datetime).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        }
                                        {
                                            booking.completedAt &&
                                            <tr>
                                                <th>Completed At:</th>
                                                <td>{moment(booking.completedAt).format('YYYY-MM-DD hh:mm a')}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(booking.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </> :
                        <div className="text-center mt-2">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>
                }

            </div>
        </div>
    )
}

export default connect(null, { viewBookingAction, markBookingPaidAction, claimBookingAction })(BookingView)
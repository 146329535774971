import React, { useState, useRef, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createNewsAction, listNewsCategoryAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { WithContext as ReactTags } from 'react-tag-input';
import Dropzone from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AsyncSelect from 'react-select/async';

const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
    tags: yup.array().of(yup.string()).optional(),
    categoryId: yup.string().required('Category is required'),
    image: yup.mixed().required("Image is required").test("fileSize", "Image must be less than 5mb", (value) => {
        if (!value) return true // attachment is optional
        return value.size <= 6000000
    })
});

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function NewsCreate({ createNewsAction, listNewsCategoryAction }) {

    const [tags, setTags] = useState([]);

    const handleDelete = i => {
        const filteredTags = tags.filter((tag, index) => index !== i);
        setTags(filteredTags);
        formik.setFieldValue('tags', filteredTags.map(item => item.text));
    };

    const handleAddition = tag => {
        const newTags = [...tags, tag];
        setTags(newTags);
        formik.setFieldValue('tags', newTags.map(item => item.text));
    };


    const handleInputChange = useCallback((newValue) => {
        const inputValue = newValue;
        return inputValue;
    });

    const loadOptions = useCallback((inputValue, callback) => {
        listNewsCategoryAction({ page: 1, perPage: 20, filters: { username: { filterVal: inputValue } } }, (err, response) => {
            if (!err) {
                let data = response.data.data.map(item => { return { value: item._id, label: item.title }; });
                callback(data);
            }
        })
    });

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags(newTags);
        formik.setFieldValue('tags', newTags.map(item => item.text));
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };


    const history = useHistory();
    const editorRef = useRef();

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            tags: [],
            image: '',
            categoryId: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('categoryId', values.categoryId);
            formData.append('imageFile', values.image);
            //    formData.append('tags', values.tags);
            formData.append('description', values.description);

            createNewsAction(formData, (err, response) => {
                if (!err) {
                    history.push('/news');
                }
            })
        },
    });
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create News
                    </h2>
                </div>
                <div className="body">
                    {console.log(formik.errors)}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                className={`form-control ${formik.errors.title && formik.touched.title && "parsley-error"
                                    }`}
                                value={formik.values.title}
                                name="title"
                                id="title"
                                onChange={formik.handleChange}
                            />
                            {
                                formik.errors.title && formik.touched.title &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.title}
                                    </li>
                                </ul>
                            }
                        </div>
                        {/* 
                        <div className="form-group">
                            <label>Tags</label>
                            <ReactTags
                                tags={tags}
                                suggestions={[]}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                inputFieldPosition="bottom"
                                autocomplete
                                classNames={{
                                    tagInputField: 'form-control'
                                }}
                            />
                            {
                                formik.errors.title && formik.touched.title &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.title}
                                    </li>
                                </ul>
                            }
                        </div> */}

                        <div className="form-group">
                            <label>Select Category</label>
                            <AsyncSelect
                                cacheOptions
                                onChange={(value) => {
                                    formik.setFieldValue('categoryId', value.value);
                                }}
                                name="collectorId"
                                loadOptions={loadOptions}
                                defaultOptions={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onInputChange={handleInputChange}
                            />
                            {
                                formik.errors.categoryId && formik.touched.categoryId &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.categoryId}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <label>Image</label>
                            <Dropzone onDrop={acceptedFiles => {
                                if (acceptedFiles.length > 0) {
                                    formik.setFieldValue('image', acceptedFiles[0]);
                                } else {
                                    formik.setFieldValue('image', '');
                                }
                            }}>
                                {({ getRootProps, getInputProps }) => (
                                    <div className="input-group">
                                        <div className="custom-file" {...getRootProps()}>
                                            <input className="custom-file-input" {...getInputProps()} />
                                            <label className="custom-file-label">{formik.values.image ? formik.values.image.name : "Choose file"}</label>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            {
                                formik.errors.image && formik.touched.image &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.image}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <CKEditor
                                editor={ClassicEditor}
                                rows={6}
                                onReady={(editor) => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle('height', '300px', editor.editing.view.document.getRoot());
                                    });
                                }}
                                config={{
                                    removePlugins: ['EasyImage', 'ImageUpload']
                                }}
                                data={formik.values.description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    formik.setFieldValue('description', data);
                                }}
                            />
                            {
                                formik.errors.description && formik.touched.description &&
                                <ul className="parsley-errors-list filled" id="parsley-id-29">
                                    <li className="parsley-required">
                                        {formik.errors.description}
                                    </li>
                                </ul>
                            }
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createNewsAction, listNewsCategoryAction })(NewsCreate);
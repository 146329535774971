import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import CategoryItemCreateComponent from "../../components/SubCategoryItem/SubCategoryItemCreate";
import { connect } from 'react-redux';
import { viewCategoryItemAction } from "../../actions";
import { useParams } from "react-router-dom";

function SubCategoryCreate({ viewCategoryItemAction }) {
    const [category, setCategoryData] = useState(null);
    const params = useParams();
    const id = params.categoryItemId;
    useEffect(() => {
        viewCategoryItemAction({ id: id }, (err, response) => {
            setCategoryData(response.data.data);
        })
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {category && <PageHeader
                        HeaderText="Category Create"
                        Breadcrumb={[
                            { name: "Categories", navigate: "/categories" },
                            { name: category.title, navigate: `/categories/view/${category._id}` },
                            { name: "Category Items", navigate: `/category-item/${category._id}` },
                            { name: "Category Create", navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        {category && <CategoryItemCreateComponent category={category} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewCategoryItemAction })(SubCategoryCreate)
import React from "react";
import PageHeader from "../../components/PageHeader";
import NewsCategoryViewComponent from "../../components/NewsCategory/NewsCategoryView";

class NewsCategoryView extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div
                style={{ flex: 1 }}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="NewsCategory Detail"
                            Breadcrumb={[
                                { name: "News Categories", navigate: "/news-categories" },
                                { name: "NewsCategory Detail", navigate: "" },
                            ]}
                        />
                        <div className="row clearfix">
                            <NewsCategoryViewComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsCategoryView;

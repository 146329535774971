import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { updateFaqAction, viewFaqAction } from '../../actions';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Loader from "react-loader-spinner";
import Select from 'react-select';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import * as _ from 'underscore';
import moment from 'moment';

const schema = yup.object().shape({
    question: yup.string().required("Question is required"),
    answer: yup.string().required("Answer is required"),
});

function FaqUpdate({ updateFaqAction, viewFaqAction }) {
    const history = useHistory();
    const [faq, setFaqData] = useState(null);
    const params = useParams();
    const id = params.id;

    const formik = useFormik({
        initialValues: {
            question: '',
            answer: '',
        },
        validationSchema: schema,
        onSubmit: values => {
            updateFaqAction(id, values, (err, response) => {
                if (!err) {
                    history.push('/faq');
                }
            })
        },
    });

    useEffect(() => {

        viewFaqAction({ id: id }, (err, response) => {
            if (!err) {
                setFaqData(response.data.data);
                formik.setValues({
                    question: response.data.data.question,
                    answer: response.data.data.answer,
                });
            }
        });
    }, [id]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {faq ?
                    <>
                        <div className="header">
                            <h2>
                                Update FAQ
                            </h2>
                        </div>
                        <div className="body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <label>Question</label>
                                    <input
                                        className={`form-control ${formik.errors.question && formik.touched.question && "parsley-error"
                                            }`}
                                        value={formik.values.question}
                                        name="question"
                                        id="question"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.question && formik.touched.question &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.question}
                                            </li>
                                        </ul>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Answer</label>
                                    <input
                                        className={`form-control ${formik.errors.answer && formik.touched.answer && "parsley-error"
                                            }`}
                                        value={formik.values.answer}
                                        name="answer"
                                        id="answer"
                                        onChange={formik.handleChange}
                                    />
                                    {
                                        formik.errors.answer && formik.touched.answer &&
                                        <ul className="parsley-errors-list filled" id="parsley-id-29">
                                            <li className="parsley-required">
                                                {formik.errors.answer}
                                            </li>
                                        </ul>
                                    }
                                </div>
                               
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Save</button>
                                </div>
                            </form>
                        </div>
                    </> :
                    <div className="text-center">
                        <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default connect(null, { updateFaqAction, viewFaqAction })(FaqUpdate);
import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import InvoiceListComponent from "../../components/Invoice/InvoiceList";



export default function InvoiceList() {
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Invoices"
                        Breadcrumb={[
                            { name: "Invoices", navigate: "" },
                        ]}
                    />
                    <div className="row clearfix">
                        <InvoiceListComponent />
                    </div>
                </div>
            </div>
        </div>
    );
}

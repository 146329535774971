import React, { useEffect, useRef, useState } from 'react';
import { useFormik, Field, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import {
    createBookingAction, listCategoryForBookingAction, listCategoryItemForBookingAction, viewSettingAction,
    listWasteTypeCategoryAction, listWasteTypeAction, listUserAction, getAddressByPostcode, applyCoupon, getVatVal
} from '../../actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import StepWizard from "react-step-wizard";
import Loader from "react-loader-spinner";
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'underscore';
import AsyncSelect from 'react-select/async';
import constants from '../../config/constants';
// import "react-datetime/css/react-datetime.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePond from '../Common/FilePond';
import loadash from 'lodash';
import moment from 'moment';

registerPlugin(FilePondPluginImagePreview);

function BookingInfo({ listUserAction, viewSettingAction, previousStep, createBookingAction, selectedItems, getAddressByPostcode, applyCoupon, getVatVal }) {

    const [users, setUsers] = useState([]);
    const [addrOptions, setAddrOptions] = useState([]);
    const [setting, setSetting] = useState(null);
    const [showAsap, setShowAsap] = useState(false);
    const [vat_val, setVatVal] = useState(20);
    const [additionalAmount, setAdditionalAmout] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [asapAmount, setAsapAmount] = useState(0);
    const [ampmAmount, setAmpmAmount] = useState(0);
    const [tempAmount, setTempAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [corporatePercentage, setCorporatePercentage] = useState(0);
    const [corporateDiscount, setCorporateDiscount] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [flatAmount, setFlatAmount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [timeOptions, setTimeOptions] = useState([
        { value: "ANYTIME", label: "Anytime" },
        { value: "AM", label: "AM" },
        { value: "PM", label: "PM" }
    ]);
    const [hasCouponCode, setHasCouponCode] = useState(false);
    const [has_introducer_code, setIntroducerCode] = useState(false);
    const pondRef = useRef();
    const history = useHistory();

    const bookingInfoSchema = yup.object().shape({
        datetime: yup.string().required('Please select datetime'),
        time: yup.string().required('Please select time'),
        asap: yup.boolean().default(false).required(),
        address: yup.string().required("Please select address"),
        postCode: yup.string().required('Postal Code is required'),
        notes: yup.string().notRequired(),
        userId: yup.string().required('User is required'),
        images: yup.array().of(yup.string()).optional(),
        has_referral_code: yup.string(),
        coupon_code: yup.string(),
        introducer_code: yup.string(),
        latitude: yup.number().required(),
        longitude: yup.number().required()
    });

    const formik = useFormik({
        initialValues: {
            datetime: '',
            time: '',
            asap: false,
            address: '',
            postCode: '',
            notes: '',
            userId: '',
            images: [],
            coupon_code: '',
            introducer_code: '',
            latitude: 0,
            longitude: 0
        },
        validationSchema: bookingInfoSchema,
        onSubmit: (values) => {
            const files = pondRef.current.getFiles();
            values.items = selectedItems;
            values.images = files.map(item => item.serverId);
            values.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (!hasCouponCode) values.coupon_code = "";
            createBookingAction(values, (err, response) => {
                if (!err) {
                    history.push('/booking');
                }
            });
        },
    });



    useEffect(() => {

        const afterMid = moment().set('hour', 14).set('minutes', 0);
        const mid = moment().set('hour', 12).set('minutes', 0);

        if (moment().isAfter(afterMid)) {
            setShowAsap(false);
        } else if (moment().isAfter(mid)) {
            setShowAsap(true);
            setTimeOptions([
                {
                    value: '',
                    label: "Select Time"
                },
                {
                    value: "Same Day",
                    label: "Same Day"
                }
            ]);
        }
    }, []);

    useEffect(() => {
        if (!hasCouponCode) {
            setFlatAmount(0);
            setPercentage(0);
            formik.setFieldValue('coupon_code', '');
        }
    }, [hasCouponCode]);


    useEffect(() => {
        viewSettingAction({}, (err, response) => {
            if (!err) {
                setSetting(response.data.data);
            }
        })
    }, []);

    useEffect(() => {
        listUserAction({ page: 1, perPage: 20, filters: { role: { filterVal: constants.ROLE.USER } } }, (err, response) => {
            if (!err) {
                setUsers(response.data.data);
            }
        })

        getVatVal((err, res) => {
            if (!err) setVatVal(res.data.data);
        });

    }, []);

    useEffect(() => {
        let totalAmount = 0;
        selectedItems.map((item, index) => {
            totalAmount += item.price * item.quantity;
        });
        setTotalAmount(loadash.round(totalAmount, 2));
        setTempAmount(loadash.round(totalAmount, 2));
    }, [selectedItems]);



    useEffect(() => {
        if ((formik.values.time === 'AM') || (formik.values.time === 'PM')) {
            setAmpmAmount(15);
        } else {
            setAmpmAmount(0);
        }
    }, [formik.values.time]);


    useEffect(() => {
        if (formik.values.asap) {
            const asapAmount = loadash.round(((tempAmount + ampmAmount) * 30) / 100, 2);
            setAsapAmount(asapAmount);
        } else {
            setAsapAmount(0);
        }
    }, [formik.values.asap])


    useEffect(() => {
        let vat = 0;
        let discountAmount = 0;
        let corporateDiscount = 0;
        let subTotal = tempAmount + ampmAmount + asapAmount;

        if (setting && (subTotal < setting.minimumOrder)) {
            setServiceCharge(setting.serviceCharge);
            subTotal = subTotal + setting.serviceCharge;
        } else {
            setServiceCharge(0);
        }

        if (corporatePercentage) {
            const discount = loadash.round(((subTotal) * corporatePercentage) / 100, 2);
            corporateDiscount = discount;
            subTotal = subTotal - discount;
            setCorporateDiscount(corporateDiscount);
        } else {
            setCorporateDiscount(corporateDiscount);
        }

        if (flatAmount) {
            discountAmount = flatAmount;
            vat = loadash.round(((subTotal - discountAmount) * vat_val) / 100, 2)
        } else if (percentage) {
            const discount = loadash.round(((subTotal) * percentage) / 100, 2);
            discountAmount = discount;
            vat = loadash.round(((subTotal - discountAmount) * vat_val) / 100, 2);
        } else {
            vat = loadash.round(((subTotal) * vat_val) / 100, 2)
        }

        setDiscountAmount(discountAmount);

        setVatAmount(vat);

        let totalAmount = loadash.round((subTotal - discountAmount + vat), 2);

        setTotalAmount(totalAmount);

        setAdditionalAmout(asapAmount + ampmAmount);

    }, [tempAmount, asapAmount, ampmAmount, vat_val, flatAmount, percentage, setting, corporatePercentage]);


    const loadOptions = (inputValue, callback) => {
        listUserAction({ page: 1, perPage: 20, filters: { role: { filterVal: [constants.ROLE.USER, constants.ROLE.CORPORATE] }, username: { filterVal: inputValue } } }, (err, response) => {
            if (!err) {
                setUsers(response.data.data);
                let data = response.data.data.map(item => { return { value: item._id, label: item.username, role: item.role, corporateDiscount: item.corporateDiscount ? item.corporateDiscount : 0 }; });
                callback(data);
            }
        })
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue;
        return inputValue;
    };

    const loadAddress = (e) => {
        e.preventDefault();
        if (formik.values.postCode) {
            getAddressByPostcode(formik.values.postCode, (err, res) => {
                let options = [];
                if (!err) {
                    console.log(" **** getAddressByPostcode **** ", res);
                    res.data.data.delivery_points.map((item, index) => {
                        let val = `${item.organisation_name} ${item.line_1}  ${item.line_2} ( ${item.udprn} )`;
                        options.push({ value: val, label: val });
                    });
                    formik.setFieldValue('latitude', res.data.data.geocode.lat);
                    formik.setFieldValue('longitude', res.data.data.geocode.lng);
                    setAddrOptions(options);
                    if (options.length == 0) {
                        setAddrOptions([]);
                    }
                } else setAddrOptions([]);
            });
        }
    }

    const applyDiscount = (e) => {
        e.preventDefault();
        if (formik.values.coupon_code) {
            let data = {
                coupon_code: formik.values.coupon_code,
                subTotal: tempAmount,
            }
            applyCoupon(data, (err, response) => {
                if (!err) {

                    const flatAmount = response.data.data.flatAmount;
                    const percentage = response.data.data.percentage;

                    if (flatAmount) {
                        setFlatAmount(flatAmount);
                        setPercentage(0);
                    } else if (percentage) {
                        setPercentage(percentage);
                        setFlatAmount(0);
                    }
                }
            });

        }
    }

    return (
        <div className="row">
            <div className="col-12 form-group">
                <div className="list-group" className="tab-pane show active">
                    {
                        selectedItems.map((item, index) => {
                            return (
                                <a onClick={() => { }} key={index} className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                        <div>
                                            <h5 className="mb-0">{item.quantity} &times; {item.title}</h5>
                                            <p className="mb-0">{item.description}</p>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="pr-5"><h6 className="mb-0">£ {item.price * item.quantity}</h6></div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                        })
                    }
                </div>
                <a onClick={() => { }} className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between align-items-right">
                        <div className="float-right">
                            {serviceCharge ? <div><b>Minimum Call Out Fee: </b> £ {serviceCharge} </div> : null}
                            {corporateDiscount ? <div><b>Corporate Discount: </b> £ {corporateDiscount} </div> : null}
                            <div><b>Additional: </b> £ {additionalAmount} </div>
                            {discountAmount ? <div><b>Discount: </b> £ {discountAmount} </div> : null}
                            <div><b>VAT: </b> £ {vatAmount} </div>
                            <div><b>TOTAL: </b> £ {totalAmount}</div>
                        </div>
                    </div>
                </a>
            </div>

            <div className="col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label>User</label>
                        <AsyncSelect
                            cacheOptions
                            onChange={(value) => {
                                if (value.role === constants.ROLE.CORPORATE) {
                                    setCorporatePercentage(value.corporateDiscount);
                                } else {
                                    setCorporatePercentage(0);
                                }
                                formik.setFieldValue('userId', value.value);
                            }}
                            name="userId"
                            loadOptions={loadOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onInputChange={handleInputChange}
                        />
                        {
                            formik.errors.userId && formik.touched.userId &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.userId}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Date</label>
                        <DatePicker name="datetime" className="form-control" selected={formik.values.datetime} onChange={(date) => formik.setFieldValue('datetime', date)} />
                        {
                            formik.errors.datetime && formik.touched.datetime &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.datetime}
                                </li>
                            </ul>
                        }
                    </div>

                    <div className="form-group">
                        <label>Time</label>
                        <Select options={timeOptions} name="time" onChange={(time) => formik.setFieldValue('time', time.value)} />
                        {
                            formik.errors.time && formik.touched.time &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.time}
                                </li>
                            </ul>
                        }
                    </div>

                    <div className="form-group">
                        {showAsap && <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onChange={(event) => { formik.setFieldValue('asap', event.target.checked) }} checked={formik.values.asap} name="asap" />
                            <label className="form-check-label">ASAP ?</label>
                        </div>}
                        {formik.values.asap && <p>Because its after Midday there is a surcharge for the same day collection.</p>}
                        {
                            formik.errors.address && formik.touched.address &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.address}
                                </li>
                            </ul>
                        }
                    </div>

                    <div className="form-group">
                        <label>Collection Postcode</label>
                        <input
                            className={`form-control ${formik.errors.postCode && formik.touched.postCode && "parsley-error"
                                }`}
                            value={formik.values.postCode}
                            name="postCode"
                            id="postCode"
                            type="text"
                            onChange={formik.handleChange}
                        />
                        <div className="py-2"><button className="btn btn-green" onClick={loadAddress}>Find Address</button></div>
                        {
                            formik.errors.postCode && formik.touched.postCode &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.postCode}
                                </li>
                            </ul>
                        }
                    </div>


                    <div className="form-group">
                        <label>Collection Address</label>
                        <Select options={addrOptions} name="address" onChange={(address) => formik.setFieldValue('address', address.value)} />
                        {
                            formik.errors.address && formik.touched.address &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.address}
                                </li>
                            </ul>
                        }
                    </div>

                    <div className="form-group">
                        <label>Notes</label>
                        <input
                            className={`form-control ${formik.errors.notes && formik.touched.notes && "parsley-error"
                                }`}
                            value={formik.values.notes}
                            name="notes"
                            id="notes"
                            type="text"
                            onChange={formik.handleChange}
                        />
                        {
                            formik.errors.notes && formik.touched.notes &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.notes}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Images</label>
                        <FilePond
                            files={formik.values.images}
                            onupdatefiles={(fileItems) => {
                                formik.setFieldValue('images', fileItems.map(fileItem => fileItem.file));
                            }}
                            allowMultiple={true}
                            maxFiles={5}
                            name="files"
                            forwordedRef={pondRef}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        <div><b>Note: </b> If you are uploading images then upload atleast 2 images.</div>
                        {
                            formik.errors.images && formik.touched.images &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.images}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onClick={(event) => {
                                if (event.target.checked) setIntroducerCode(true); else setIntroducerCode(false);
                            }} checked={has_introducer_code} name="introducer_code" />
                            <label className="form-check-label">Do you have Referral Code ?</label>
                        </div>
                    </div>

                    {has_introducer_code &&
                        <div className="form-group">
                            <label>Referral Code</label>
                            <input
                                className={`form-control ${formik.errors.coupon_code && formik.touched.coupon_code && "parsley-error"
                                    }`}
                                value={formik.values.introducer_code}
                                name="introducer_code"
                                id="introducer_code"
                                type="text"
                                onChange={formik.handleChange}
                            />

                        </div>
                    }

                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" onClick={(event) => {
                                if (event.target.checked) setHasCouponCode(true); else setHasCouponCode(false);
                            }} checked={hasCouponCode} name="coupon_code" />
                            <label className="form-check-label">Do you have Discount Code ?</label>
                        </div>
                    </div>

                    {hasCouponCode &&
                        <div className="form-group">
                            <label>Discount Code</label>
                            <input
                                className={`form-control ${formik.errors.coupon_code && formik.touched.coupon_code && "parsley-error"
                                    }`}
                                value={formik.values.coupon_code}
                                name="coupon_code"
                                id="coupon_code"
                                type="text"
                                onChange={formik.handleChange}
                            />

                            <div className="py-2"><button className="btn btn-green" onClick={applyDiscount}>Apply</button></div>

                        </div>
                    }


                </form>
                <div className="d-flex justify-content-center mt-3">
                    <div className="px-2"><button className="btn btn-green" onClick={previousStep}>prev</button></div>
                    <div className="px-2"><button className="btn btn-green" onClick={formik.handleSubmit}>Submit</button></div>


                </div>
            </div>
        </div>
    )
}

BookingInfo = connect(null, { listUserAction, createBookingAction, viewSettingAction })(BookingInfo)


function AdditionalInfoModal({ show, handleClose, currentItem, onItemSelect, listWasteTypeCategoryAction, listWasteTypeAction }) {

    const [categories, setCategories] = useState([]);
    const [wasteTypes, setWasteTypes] = useState([]);
    const history = useHistory();
    const additionalInfoSchema = yup.object().shape({
        wasteTypeCategoryIds: yup.array().of(yup.string().required("Please Waste Type")).min(1, "Please select at least one Waste Type"),
        weight: yup.number().required("Please enter weight").min(0, "Invalid weight"),
    });

    useEffect(() => {
        listWasteTypeCategoryAction({ page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setCategories(response.data.data);
            }
        });
        listWasteTypeAction({ page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setWasteTypes(response.data.data);
            }
        });
    }, []);




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            wasteTypeCategoryIds: [],
            weight: 0,
            wasteTypeId: ''
        },
        validationSchema: additionalInfoSchema,
        onSubmit: (values, { resetForm }) => {
            values.categoryItemId = currentItem._id;
            values.quantity = 1;
            values.title = currentItem.title;
            values.description = currentItem.description;
            values.price = currentItem.price;
            onItemSelect(values);
            resetForm({
                wasteTypeCategoryIds: [],
                weight: 0,
                wasteTypeId: ''
            });
            handleClose();
        },
    });

    const onHide = () => {
        formik.resetForm({
            wasteTypeCategoryIds: [],
            weight: 0,
            wasteTypeId: ''
        }); handleClose();
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Additional Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <label>Waste Type</label>
                        <Select
                            defaultValue={[]}
                            isMulti
                            onChange={(value) => {
                                formik.setFieldValue('wasteTypeCategoryIds', value.map(item => item.value));
                            }}
                            name="colors"
                            options={categories.map(item => { return { value: item._id, label: item.title }; })}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        {
                            formik.errors.wasteTypeCategoryIds && formik.touched.wasteTypeCategoryIds &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.wasteTypeCategoryIds}
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="form-group">
                        <label>Weight</label>
                        <input
                            className={`form-control ${formik.errors.weight && formik.touched.weight && "parsley-error"
                                }`}
                            value={formik.values.weight}
                            name="weight"
                            id="weight"
                            type="number"
                            onChange={formik.handleChange}
                        />
                        {
                            formik.errors.weight && formik.touched.weight &&
                            <ul className="parsley-errors-list filled" id="parsley-id-29">
                                <li className="parsley-required">
                                    {formik.errors.weight}
                                </li>
                            </ul>
                        }
                    </div>

                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={formik.handleSubmit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

AdditionalInfoModal = connect(null, { listWasteTypeCategoryAction, listWasteTypeAction })(AdditionalInfoModal)

function ChooseItem({ categories, listCategoryItemForBookingAction, onItemSelect, selectedItems, increaseQuantity, descreaseQuantity, previousStep, nextStep }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [category, setCategory] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [categoryItems, setCategoryItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const selectItem = (item) => {
        if (category.type === 0) {
            setCurrentItem(item);
            setShow(true);
        } else {
            let values = {};
            setCurrentItem(item);
            values.categoryItemId = item._id;
            values.quantity = 1;
            values.title = item.title;
            values.price = item.price;
            values.description = item.description;
            onItemSelect(values);
        }
    }


    const onTabChange = (index, item) => {
        setSelectedTab(index);
        setCategory(item);
        setLoading(true);
        listCategoryItemForBookingAction({ filters: { categoryId: { filterVal: item._id } }, page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setCategoryItems(response.data.data);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if (categories.length > 0) {
            setCategory(categories[0]);
            listCategoryItemForBookingAction({ filters: { categoryId: { filterVal: categories[0]._id } }, page: 1, perPage: 100 }, (err, response) => {
                if (!err) {
                    setCategoryItems(response.data.data);
                }
                setLoading(false);
            });
        }
    }, [categories.length]);

    return (
        <>
            <AdditionalInfoModal currentItem={currentItem} onItemSelect={onItemSelect} handleClose={handleClose} show={show} />
            <ul className="nav nav-tabs-new2" role="tablist">
                {
                    categories.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={(selectedTab === index) ? "nav-item mr-1 active" : "nav-item mr-1"}
                                id={"bacicTab2-" + index}
                                role="presentation"
                                className={item.thirdLevelAllow ? "red" : ""}
                                onClick={() => {
                                    onTabChange(index, item);
                                }}
                            >
                                <a style={{ cursor: "pointer" }} className={(selectedTab === index) ? "nav-link active" : "nav-link"}>{item.title}</a>
                            </li>
                        );
                    })
                }
            </ul>
            <div className="tab-content">
                {(!loading) ? <div className="list-group" className="tab-pane show active">
                    {
                        categoryItems.map(item => {
                            let existingItem = _.find(selectedItems, item2 => item2.categoryItemId === item._id);
                            return (
                                <a onClick={() => { }} key={item._id} className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between">
                                        <div>
                                            <h5 className="mb-0">{item.title}</h5>
                                            <p className="mb-0">{item.description}</p>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <div className="px-3 px-md-5"><h6 className="mb-0">£{item.price}</h6></div>
                                                <div>
                                                    {
                                                        (!existingItem) ? <button className="btn btn-green" onClick={() => { selectItem(item) }}>Select</button> :
                                                            <div className="input-group plus-minus" style={{ "flex-wrap": "inherit" }}>
                                                                <span className="input-group-prepend">
                                                                    <button type="button" className="btn btn-green btn-number" onClick={() => { descreaseQuantity(existingItem) }} data-type="minus" data-field="quant[1]">
                                                                        <span className="fa fa-minus"></span>
                                                                    </button>
                                                                </span>
                                                                <input type="text" name="quant[1]" className="form-control input-number" value={existingItem.quantity} min="1" max="10" readOnly />
                                                                <span className="input-group-append">
                                                                    <button type="button" className="btn btn-green btn-number" onClick={() => increaseQuantity(existingItem)} data-type="plus" data-field="quant[1]">
                                                                        <span className="fa fa-plus"></span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </a>
                            )
                        })
                    }
                </div> : <div className="text-center mt-2">
                    <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                    />
                </div>}
                <div className="d-flex justify-content-center mt-3">
                    <div className="px-2"><button className="btn btn-green" onClick={previousStep}>prev</button></div>
                    <div className="px-2"><button className="btn btn-green" disabled={(selectedItems.length === 0)} onClick={nextStep}>Next</button></div>
                </div>
            </div>
        </>
    );
}



function BookingCreate({ listCategoryForBookingAction, viewSettingAction, listCategoryItemForBookingAction, getAddressByPostcode, applyCoupon, getVatVal }) {
    const [categories, setCategories] = useState([]);

    const [items, setItems] = useState([]);

    useEffect(() => {
        listCategoryForBookingAction({ page: 1, perPage: 100 }, (err, response) => {
            if (!err) {
                setCategories(response.data.data);
            }
        })
    }, [])


    const itemSelect = (item) => {
        let newItems = [...items];
        newItems.push(item);
        setItems(newItems);
    }

    const increaseQuantity = (item) => {
        let newItems = items.map(item2 => {
            if (item.categoryItemId === item2.categoryItemId) {
                item2.quantity = item2.quantity + 1;
            }
            return item2;
        });
        setItems(newItems);
    }


    const descreaseQuantity = (item) => {
        if (item.quantity === 1) {
            let newItems = items.filter(item2 => {
                return item.categoryItemId !== item2.categoryItemId;
            });
            setItems(newItems);
        } else {
            let newItems = items.map(item2 => {
                if (item.categoryItemId === item2.categoryItemId) {
                    item2.quantity = item2.quantity - 1;
                }
                return item2;
            });
            setItems(newItems);
        }
    }



    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    <h2>
                        Create Booking
                    </h2>
                </div>
                <div className="body">
                    <StepWizard>
                        <ChooseItem categories={categories} onItemSelect={itemSelect} increaseQuantity={increaseQuantity} descreaseQuantity={descreaseQuantity} selectedItems={items} viewSettingAction={viewSettingAction} listCategoryItemForBookingAction={listCategoryItemForBookingAction} />
                        <BookingInfo selectedItems={items} getAddressByPostcode={getAddressByPostcode} applyCoupon={applyCoupon} getVatVal={getVatVal} />
                    </StepWizard>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { createBookingAction, viewSettingAction, listCategoryForBookingAction, listCategoryItemForBookingAction, getAddressByPostcode, applyCoupon, getVatVal })(BookingCreate);
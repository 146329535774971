import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import WasteTypeCategoryViewComponent from "../../components/WasteTypeCategory/WasteTypeCategoryView";
import { useParams } from "react-router-dom";
import { viewWasteTypeCategoryAction } from "../../actions";
import { connect } from 'react-redux';

 function WasteTypeCategoryView( { viewWasteTypeCategoryAction } ) {
    const params = useParams();
    const id = params.id;
    const [wasteType, setWasteTypeCategoryData] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewWasteTypeCategoryAction({ id: id }, (err, response) => {
                if (!err) {
                    setWasteTypeCategoryData(response.data.data);
                }
            });
        }
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {wasteType && <PageHeader
                        HeaderText="Waste Type Detail"
                        Breadcrumb={[
                            { name: "Waste Types", navigate: "/waste-type-category" },
                            { name: wasteType.title, navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <WasteTypeCategoryViewComponent wasteType={wasteType}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewWasteTypeCategoryAction })(WasteTypeCategoryView)

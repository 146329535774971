import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import SubCategoryItemViewComponent from "../../components/SubCategoryItem/SubCategoryItemView";
import { useParams } from "react-router-dom";
import { viewSubCategoryItemAction } from "../../actions";
import { connect } from 'react-redux';

 function SubCategoryItemView( { viewSubCategoryItemAction } ) {
    const params = useParams();
    const id = params.id;
    const [categoryItem, setCategoryItemData] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (id) {
            viewSubCategoryItemAction({ id: id }, (err, response) => {
                if (!err) {
                    setCategoryItemData(response.data.data);
                }
            });
        }
    }, [id]);
    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {categoryItem && <PageHeader
                        HeaderText="Category Detail"
                        Breadcrumb={[
                            { name: "Categories", navigate: "/categories" },
                            { name: categoryItem.title, navigate: `/sub-category-item/view/${categoryItem._id}` },
                            { name: 'Sub Category Items', navigate: `/sub-category-item/${categoryItem.categoryId._id}/${categoryItem.categoryItemId._id}` },
                            { name: categoryItem.title, navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <SubCategoryItemViewComponent categoryItem={categoryItem}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewSubCategoryItemAction })(SubCategoryItemView)

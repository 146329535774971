import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { viewNewsAction } from '../../actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function NewsView({ viewNewsAction }) {
    const [newsData, setNewsData] = useState(null);
    const history = useHistory();
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        viewNewsAction({ id: id }, (err, response) => {
            if (!err) {
                setNewsData(response.data.data);
            }
        });
    }, [id, viewNewsAction]);

    return (
        <div className="col-lg-12">
            <div className="card">
                {
                    newsData ?
                        <>
                            <div className="header d-flex justify-content-between align-items-center">
                                <h2>
                                    {newsData.title}
                                </h2>
                            </div>
                            <div className="body table-responsive">
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Title:</th>
                                            <td>{newsData.title}</td>

                                        </tr>
                                        <tr>
                                            <th>Image:</th>
                                            <td><img width="60" src={newsData.imagePath} className="rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th>Description:</th>
                                            <td>{newsData.description}</td>
                                        </tr>

                                        <tr>
                                            <th>Category:</th>
                                            <td>{newsData.categoryId ? newsData.categoryId.title : ''}</td>
                                        </tr>

                                        <tr>
                                            <th>Created At:</th>
                                            <td>{moment(newsData.createdAt).format('YYYY-MM-DD hh:mm a')}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </> :
                        <div className="text-center">
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={3000} //3 secs
                            />
                        </div>

                }

            </div>
        </div>
    )
}

export default connect(null, { viewNewsAction })(NewsView)
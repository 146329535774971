import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/logo.png";
import { loginAction, notifyOnClose } from "../actions";
import { Formik } from 'formik';
import { Toast } from "react-bootstrap";
import * as yup from 'yup';
import { withRouter } from "react-router";

let schema = yup.object().shape({
	username: yup.string().email().required('Email is required'),
	password: yup.string().required('Password is required')
});


class Login extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoad: true
		}
	}
	componentDidMount() {
		setTimeout(() => {
			this.setState({
				isLoad: false
			})
		}, 500);
		document.body.classList.remove("theme-cyan");
		document.body.classList.remove("theme-purple");
		document.body.classList.remove("theme-blue");
		document.body.classList.remove("theme-green");
		document.body.classList.remove("theme-orange");
		document.body.classList.remove("theme-blush");
	}
	render() {
		const { navigation, notifyData } = this.props;
		const { email, password } = this.props;
		return (
			<div className="theme-cyan">
				<Toast
					id="toast-container"
					show={true}
					className={`toast-top-right toast-container`}
					style={{ background: "transparent" }}
					onClose={() => { }}
				>
					{notifyData.map((data, i) => {
						if (data.position === "top-right") {
							setTimeout(() => {
								this.props.notifyOnClose(i);
							}, 10000);
							return (
								<Toast.Body
									key={"keys" + i}
									className={`toast-${data.type} mb-10 ml-0`}
									style={{ opacity: 1 }}
								>
									<strong className="mr-auto"> {data.dialogText} </strong>
									<button
										className="toast-close-button"
										onClick={() => {
											this.props.notifyOnClose(i);
										}}
									>
										x
									</button>
								</Toast.Body>
							);
						}
					})}
				</Toast>
				<div className="page-loader-wrapper" style={{ display: this.state.isLoad ? 'block' : 'none' }}>
					<div className="loader">
						<div className="m-t-30"><img src={require('../assets/images/logo-white.svg')} width="48" height="48" alt="Trash2Go" /></div>
						<p>Please wait...</p>
					</div>
				</div>
				<div className="hide-border">
					<div className="vertical-align-wrap">
						<div className="vertical-align-middle auth-main">
							<div className="auth-box">
								<div className="top">
									<img src={Logo} alt="Trash2Go" style={{ height: "40px", margin: "10px" }} />
								</div>
								<div className="card">
									<div className="header">
										<p className="lead">Login to your account</p>
									</div>
									<div className="body">
										<Formik
											initialValues={{
												username: '',
												password: ''
											}}
											validationSchema={schema}
											onSubmit={async (values) => {
												this.props.loginAction(values, (err, response) => {
													if (!err) {
														this.props.history.push('/dashboard');
													}
												});
											}}
										>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur,
												handleSubmit,
												isSubmitting
											}) => (
												<form onSubmit={handleSubmit}>
													<div className="form-auth-small">
														<div className="form-group">
															<label className="control-label sr-only">Email</label>
															<input
																className="form-control"
																id="signin-email"
																placeholder="Email"
																type="email"
																name="username"
																value={values.username}
																onChange={handleChange}
															/>
															{errors.username && touched.username ? (
																<ul className="parsley-errors-list filled" id="parsley-id-29">
																	<li className="parsley-required">
																		{errors.username}
																	</li>
																</ul>
															) : null}
														</div>
														<div className="form-group">
															<label className="control-label sr-only">
																Password
															</label>
															<input
																className="form-control"
																id="signin-password"
																placeholder="Password"
																type="password"
																name="password"
																value={values.password}
																onChange={handleChange}
															/>
															{errors.password && touched.password ? (
																<ul className="parsley-errors-list filled" id="parsley-id-29">
																	<li className="parsley-required">
																		{errors.password}
																	</li>
																</ul>
															) : null}
														</div>
														<div className="form-group clearfix">
															<label className="fancy-checkbox element-left">
																<input type="checkbox" />
																<span>Remember me</span>
															</label>
														</div>
														<button type="submit"
															className="btn btn-primary btn-lg btn-block"
															href="dashboard"
														>Login</button>
														<div className="bottom">
															<span className="helper-text m-b-10">
																<i className="fa fa-lock"></i>{" "}
																<a href={`${process.env.PUBLIC_URL}/forgotpassword`}
																>
																	Forgot password?
																</a>
															</span>
														</div>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ UIElementsReducer }) => {
	return {
		notifyData: UIElementsReducer.notifyData
	};
}


export default connect(mapStateToProps, {
	loginAction,
	notifyOnClose
})(withRouter(Login));

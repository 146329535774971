import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import AssignCollectorsComponent from "../../components/AppUsers/AssignCollectors";
import { viewUserAction } from "../../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

function AssignCollectors({ viewUserAction }) {
    const params = useParams();
    const id = params.id;
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (id) {
            viewUserAction({ id: id }, (err, response) => {
                if (!err) {
                    setUserData(response.data.data);
                }
            });
        }
    }, [id]);

    return (
        <div
            style={{ flex: 1 }}
            onClick={() => {
                document.body.classList.remove("offcanvas-active");
            }}
        >
            <div>
                <div className="container-fluid">
                    {userData && <PageHeader
                        HeaderText="Assign Collectors"
                        Breadcrumb={[
                            { name: "Users", navigate: "/app-users" },
                            { name: userData.username, navigate: `/app-users/view/${userData._id}` },
                            { name: 'Assign Collectors', navigate: `` },
                        ]}
                    />}
                    <div className="row clearfix">
                        <AssignCollectorsComponent />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { viewUserAction })(AssignCollectors);

import React from "react";
import PageHeader from "../../components/PageHeader";
import VehicleCreateComponent from "../../components/Vehicle/VehicleCreate";

class VehicleCreate extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div
                style={{ flex: 1 }}
                onClick={() => {
                    document.body.classList.remove("offcanvas-active");
                }}
            >
                <div>
                    <div className="container-fluid">
                        <PageHeader
                            HeaderText="Vehicle Create"
                            Breadcrumb={[
                                { name: "Categories", navigate: "/categories" },
                                { name: "Vehicle Create", navigate: "" },
                            ]}
                        />
                        <div className="row clearfix">
                            <VehicleCreateComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleCreate;
